<template>
  <div class="content-main">
    <Breadcrumbs background="#f1f1f1" />
    <div v-if="response.items.thumbnail && response.items.thumbnail.large">
      <ModalImage
        v-show="showModal"
        @close-modal="showModal = false"
        :imageData="
          response.items.thumbnail && response.items.thumbnail.large
            ? response.items.thumbnail.large
            : 'https://narasi.tv/storage/images/dummy.png'
        "
      />
    </div>
    <div class="container">
      <ShimmerArticleLinimasa v-if="response.loading" />
      <div class="article-wrapper" v-if="!response.loading && response.items">
        <div
          class="img-wrapper"
          v-if="response.items.thumbnail && response.items.thumbnail.large"
        >
          <ImageResponsive
            :imageUrl="
              response.items.thumbnail && response.items.thumbnail.large
                ? response.items.thumbnail.large
                : 'https://narasi.tv/storage/images/dummy.png'
            "
            :fromUrl="true"
            class="card-img-top"
            alt="image-linimasa"
          />
        </div>
        <div class="description">
          <div class="schedule d-flex">
            <div class="card-location fs-14">{{ response.items.location }}</div>
            <span class="px-2">&bull;</span>
            <div class="card-datetime fs-14">
              {{ $moment(response.items.startDate).format("DD/MM/YYYY") }}
            </div>
          </div>
          <div class="card-title d-block">
            {{ response.items.title }}
          </div>
          <div class="card-desc">
            {{ response.items.description }}
          </div>
          <button
            class="btn btn-lihat-foto fs-14 px-4"
            data-toggle="modal"
            data-target="#exampleModal"
            @click="showModal = true"
          >
            LIHAT FOTO
          </button>
        </div>
      </div>
      <section class="recommend-event row">
        <div class="container">
          <TitleSection
            title="LINIMASA EVENT LAINNYA"
            v-if="$store.state.events.eventDataRecommend.items.length > 0"
          />
          <div class="position-relative">
            <div
              v-swiper:mySwiper="swiperOptions"
              @transition-start="onSwiperSlideChange()"
            >
              <div class="swiper-wrapper">
                <ShimmerListCard
                  v-if="$store.state.events.eventDataRecommend.loading"
                />
                <CardEighteen
                  v-else
                  v-show="
                    $store.state.events.eventDataRecommend.items.length > 0
                  "
                  v-for="(card, index) in $store.state.events.eventDataRecommend
                    .items"
                  :key="'card-linimasa-' + index"
                  :image="
                    card.thumbnail && card.thumbnail.large
                      ? card.thumbnail.large
                      : 'https://narasi.tv/storage/images/dummy.png'
                  "
                  :imageFromUrl="true"
                  :organizer="card.organizer"
                  :categories="card.categories"
                  :location="card.location"
                  :datetime="card.startDate"
                  :title="card.title"
                  :slug="card.slug"
                  :description="card.description"
                  :to="{
                    name: 'event-linimasa-event-narasi-slug',
                    params: { slug: card.slug },
                  }"
                  class="mb-3 swiper-slide"
                />
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div
              class="shadow-arrow shadow-left"
              :class="
                arrow.prev ||
                $store.state.events.eventDataRecommend.items.length < 4
                  ? 'hide'
                  : ''
              "
            ></div>
            <div
              class="shadow-arrow shadow-right"
              :class="
                arrow.next ||
                $store.state.events.eventDataRecommend.items.length < 4
                  ? 'hide'
                  : ''
              "
            ></div>
            <Icons
              name="arrow-left"
              class="arrow prev"
              :class="
                arrow.prev ||
                $store.state.events.eventDataRecommend.items.length < 4
                  ? 'hide'
                  : ''
              "
            />
            <Icons
              name="arrow-right"
              class="arrow next"
              :class="
                arrow.next ||
                $store.state.events.eventDataRecommend.items.length < 4
                  ? 'hide'
                  : ''
              "
            />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../../config";
import ModalImage from "/components/event/ModalImage";
import CardEighteen from "/components/cards/CardEighteen";
import Breadcrumbs from "/components/Breadcrumbs";
import ImageResponsive from "/components/ImageResponsive";
import TitleSection from "/components/TitleSection";
import Icons from "/components/Icons";
import ShimmerArticleLinimasa from "/components/shimmer/ArticleLinimasa";
import ShimmerListCard from "/components/shimmer/ListCard";

export default {
  async asyncData({ app, params, $axios, store, route, error }) {
    try {
      const detail = await app.$axios.$get(
        `${config.EVENT_SERVICE_API}events/detail/${params.slug}`
      );
      store.commit("seo/SET_SEO", {
        title: detail.data.title,
        desc: detail.data.description
          ? detail.data.description
          : detail.data.title,
        image:
          detail.data.thumbnail && detail.data.thumbnail.large
            ? detail.data.thumbnail.large
            : "https://narasi.tv/icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });

      let tes = [];
      for (const x in detail.data.categories) {
        tes.push(detail.data.categories[x].id);
      }
      let x = JSON.stringify(tes);
      const categoriesRecommend = x.substr(1, x.length - 2);

      let response = {
        items: detail.data,
        loading: false,
      };
      return { response, categoriesRecommend };
    } catch (e) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: {
    Icons,
    TitleSection,
    ShimmerListCard,
    ImageResponsive,
    ShimmerArticleLinimasa,
    Breadcrumbs,
    ModalImage,
    CardEighteen,
  },
  data() {
    return {
      showModal: false,
      page: 1,
      arrow: {
        prev: true,
        next: false,
      },
      swiperOptions: {
        pagination: false,
        autoHeight: true,
        calculateHeight: true,
        // autoplay: {
        //   delay: 5000,
        // },
        navigation: {
          prevEl: ".prev",
          nextEl: ".next",
        },
        breakpoints: {
          1023: {
            slidesPerView: 3.5,
            spaceBetween: 16,
          },
          320: {
            slidesPerView: 1.2,
            spaceBetween: 16,
          },
        },
      },
    };
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
  },
  methods: {
    onSwiperSlideChange() {
      this.arrow.next = this.mySwiper.isEnd;
      this.arrow.prev = this.mySwiper.isBeginning;
    },
  },
  mounted() {
    this.$store.dispatch("events/dataEventRecommend", {
      categories: this.categoriesRecommend,
      eventId: this.response.items.id,
    });
  },
};
</script>

<style lang="scss" scoped>
.card-location {
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
   -webkit-line-clamp: 1;
   display: -webkit-box;
   -webkit-box-orient: vertical;
}
.content-main {
  padding: 0 0 48px;

  .container {
    .article-wrapper {
      display: flex;
      margin-bottom: 48px;
      @media only screen and (max-width: 1024px) {
        flex-direction: column;
        margin-bottom: 32px;
      }

      .img-wrapper {
        width: 50%;
        @media only screen and (max-width: 1024px) {
          width: 100%;
          margin-bottom: 20px;
        }
        .image {
          height: auto;
        }
      }

      .description {
        width: 50%;
        padding: 0 50px;
        @media only screen and (max-width: 1024px) {
          width: 100%;
          padding: 0;
        }

        .schedule {
          margin-bottom: 15px;
          color: #4a25aa;
        }
      }

      .category-wrapper {
      }

      .card-title {
        font-size: 24px;
        font-weight: 500;
        color: #051c2c;
        margin-bottom: 15px;
        @media only screen and (max-width: 1024px) {
          line-height: 28px;
        }

        &:hover {
          color: #051c2c;
        }
      }

      .card-desc {
        font-size: 14px;
        margin-bottom: 15px;
        line-height: 28px;
        text-align: justify;
      }

      .btn-lihat-foto {
        height: 50px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: #fafafa;
        border: 1px solid #4a25aa;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #4a25aa;
        padding: 0 20px;
        @media only screen and (max-width: 1024px) {
          height: 40px;
          &:hover {
            color: #4a25aa !important;
          }
        }
        @media only screen and (min-width: 1024px) {
          &:hover {
            color: #8166c8 !important;
            border-color: #8166c8 !important;
          }
          &:active {
            color: #6242b4 !important;
            border: 2px solid #6242b4;
          }
        }
      }
    }
    .recommend-event {
      position: relative;

      .container {
        .title {
          margin-bottom: 24px;
          @media only screen and (max-width: 1024px) {
            margin-bottom: 16px;
          }
        }

        .shadow-arrow {
          pointer-events: none;
          position: absolute;
          top: 0;
          width: 100px;
          height: 100%;
          padding: 0;
          background: rgb(0, 0, 0);
          z-index: 2;
          opacity: 1;
          transition: ease-in-out 0.5s;
          @media only screen and (max-width: 1024px) {
            display: none;
          }

          &.shadow-left {
            left: 0;
            background: linear-gradient(
              to left,
              hsla(0, 0%, 100%, 0),
              rgba(241, 241, 241, 1)
            );
          }

          &.shadow-right {
            right: 0;
            background: linear-gradient(
              to right,
              hsla(0, 0%, 100%, 0),
              rgba(241, 241, 241, 1)
            );
          }

          &.hide {
            opacity: 0;
            z-index: 0;
          }
        }

        .arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
          width: auto;
          @media only screen and (max-width: 1024px) {
            display: none;
          }

          &.prev {
            left: 0;
          }

          &.next {
            right: 0;
          }

          &.hide {
            display: none;
          }
        }

        .swiper-container {
          @media only screen and (max-width: 1024px) {
            padding: 20px;
            margin: -20px;
          }
          .swiper-wrapper {
            height: fit-content !important;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="content-main">
    <ModalLogin
      :redirect="$route.path"
      @registeredModal="modalLogin = $event"
    />
    <ModalShare @registeredModal="modalShare = $event" />
    <div class="buy-success">
      <div class="container">
        <h2 class="title">Pendaftaran Berhasil! <Icons name="success" /></h2>
        <p class="desc">
          Pendaftaran event {{ response.data.title }} berhasil diproses. Silakan
          cek email konfirmasi untuk melihat detail event. <br />
          <Link
            :to="{
              name: 'event-organizer-slug',
              params: {
                organizer: $route.params.organizer,
                slug: $route.params.slug,
              },
            }"
            >Kembali Ke Event</Link
          >
        </p>
      </div>
    </div>
    <div class="container">
      <div class="card-event">
        <div class="primary" :class="{ fullWidth: !order.data.qrcode }">
          <div
            class="thumbnail"
            v-if="response.data.thumbnail && response.data.thumbnail.large"
          >
            <ImageResponsive
              :imageUrl="
                response.data.thumbnail.large
                  ? response.data.thumbnail.large
                  : 'https://narasi.tv/storage/images/dummy.png'
              "
              :fromUrl="true"
            />
            <div class="copy-wrapper-mobile">
              <div
                class="category-wrapper"
                v-if="response.data && response.data.categories"
              >
                <p
                  class="category"
                  v-for="category in response.data.categories.length > 0
                    ? response.data.categories.length > 2
                      ? response.data.categories.slice(0, 2)
                      : response.data.categories
                    : [
                        {
                          id: '1',
                          title: 'Uncategorized',
                        },
                      ]"
                  :key="category.id"
                >
                  {{ category.title }} <span>&bull;</span>
                </p>
              </div>
              <span
                v-if="response.data.title"
                class="title"
              >
                {{ response.data.title }}
              </span>
            </div>
          </div>
          <div class="copy-wrapper">
            <div
              class="category-wrapper"
              v-if="response.data && response.data.categories"
            >
              <p
                class="category"
                v-for="category in response.data.categories.length > 0
                  ? response.data.categories.length > 2
                    ? response.data.categories.slice(0, 2)
                    : response.data.categories
                  : [
                      {
                        id: '1',
                        title: 'Uncategorized',
                      },
                    ]"
                :key="category.id"
              >
                {{ category.title }} <span>&bull;</span>
              </p>
            </div>
            <span
              v-if="response.data.title"
              class="title"
            >
              {{ response.data.title }}
            </span>
            <div class="box-wrapper">
              <div class="box location">
                <ImageResponsive
                  imageUrl="icons/place.webp"
                  class="is-desktop"
                />
                <p class="label is-mobile">Tempat</p>
                <p class="desc-label">
                  {{ response.data.location }}
                </p>
              </div>
              <div class="box email">
                <ImageResponsive
                  imageUrl="icons/mail.webp"
                  class="is-desktop"
                />
                <p class="label is-mobile">Email</p>
                <p class="desc-label">
                  {{ order.data.email }}
                </p>
              </div>
              <div class="box datetime">
                <ImageResponsive
                  imageUrl="icons/calendar.webp"
                  class="is-desktop"
                />
                <p class="label is-mobile">Tanggal</p>
                <p class="desc-label">
                  {{ formatDate }}
                </p>
              </div>
              <div class="box event-type">
                <ImageResponsive
                  imageUrl="icons/library.webp"
                  class="is-desktop"
                />
                <p class="label is-mobile">Jenis Event</p>
                <p class="desc-label">
                  {{ response.data.link ? "Online" : "Offline" }}
                </p>
              </div>
              <div class="box schedule">
                <ImageResponsive
                  imageUrl="icons/time.webp"
                  class="is-desktop"
                />
                <p class="label is-mobile">Waktu</p>
                <p class="desc-label">
                  {{
                    $moment(response.data.startDate).utc().format("LT") +
                    " - " +
                    $moment(response.data.endDate).utc().format("LT")
                  }}
                </p>
              </div>
              <div class="box organizer">
                <ImageResponsive
                  imageUrl="icons/groups.webp"
                  class="is-desktop"
                />
                <p class="label is-mobile">Penyelenggara</p>
                <p class="desc-label">
                  {{ response.data.organizer }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="secondary" v-if="order.data.qrcode">
          <QrcodeVue
            :value="order.data.qrcode"
            :size="160"
            level="H"
            class="qrcode-wrapper"
          />
          <p class="text">
            Tunjukkan kode QR ini kepada petugas di pintu masuk acara
          </p>
        </div>
      </div>
      <!-- <div class="list-ticket">
        <div class="tickets">
          <div
            class="card-ticket"
            @mouseover="bgTicket = true"
            @mouseleave="bgTicket = false"
          >
            <img
              src="/storage/images/event/bg-ticket-desktop.png"
              class="bg-ticket for-desktop"
              :class="bgTicket ? 'hover' : ''"
            />
            <img
              src="/storage/images/event/bg-ticket-mobile.png"
              class="bg-ticket for-mobile"
              :class="bgTicket ? 'hover' : ''"
            />
            <div class="copy-wrapper">
              <div class="primary">
                <h2
                  class="ticket-type"
                  v-if="order.data.ticketRaw && order.data.ticketRaw.name"
                >
                  {{ order.data.ticketRaw.name.toUpperCase() }}
                </h2>
                <p class="exp">
                  Berakhir tanggal <br />
                  {{
                    $moment(order.data.ticketRaw.endDate)
                      .utc()
                      .format("DD MMMM Y | H.SS")
                  }}
                  WIB
                </p>
              </div>
              <div class="secondary">
                <p
                  class="desc"
                  v-html="
                    order.data.ticketRaw.description
                      ? order.data.ticketRaw.description
                      : `${$store.state.auth.user.fullname} <br>
                    ${$store.state.auth.user.email}`
                  "
                ></p>
                <div class="price-discount">
                  <p class="discount">
                    {{ changeToRupiah(order.data.ticketRaw.price) }}
                  </p>
                  <h2 class="price">
                    {{
                      getPrice(
                        order.data.ticketRaw.price,
                        order.data.ticketRaw.discount
                      )
                    }}
                  </h2>
                </div>
                <div class="sold-out">TIKET HABIS</div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <section class="description-event">
        <div class="accordion" id="accordionExample">
          <div
            class="accordion-item"
            :class="{ 'first-item': response.data.tnc !== '' }"
          >
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Deskripsi
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
            >
              <div
                class="accordion-body"
                v-html="response.data.description"
              ></div>
            </div>
          </div>
          <div v-if="response.data.tnc !== ''" class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                Syarat & Ketentuan Berlaku
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse show"
              aria-labelledby="headingTwo"
            >
              <div class="accordion-body" v-html="response.data.tnc"></div>
            </div>
          </div>
        </div>
      </section>

      <div
        class="recommend"
        v-show="eventsOngoing.items && eventsOngoing.items.length > 0"
      >
        <h2 class="title">Nikmati Event pilihan yang bisa kamu akses disini</h2>
        <p class="desc">
          Event rekomendasi untuk perluas wawasan dan update informasi harian
          kamu
        </p>
        <div class="position-relative">
          <div
            v-swiper:mySwiper="swiperOptions"
            @transition-start="onSwiperSlideChange()"
          >
            <div class="swiper-wrapper">
              <CardEleven
                class="swiper-slide"
                v-for="(card, index) in eventsOngoing.items"
                :key="index"
                :image="
                  card.thumbnail && card.thumbnail.large
                    ? card.thumbnail.large
                    : 'https://narasi.tv/storage/images/dummy.png'
                "
                :imageFromUrl="true"
                :ticket="card.ticketType"
                :categories="card.categories"
                :location="card.location"
                :title="card.title"
                :slug="card.slug"
                :description="card.description"
                :datetime="card.startDate"
                :price="card.price"
                :discount="card.discount"
                :to="{
                  name: 'event-organizer-slug',
                  params: {
                    organizer: card.organizer,
                    slug: card.slug,
                  },
                }"
                :startEvent="card.startDate"
                :endEvent="card.endDate"
                :startRegis="card.startRegistrationDate"
                :endRegis="card.endRegistrationDate"
              />
            </div>
            <div class="swiper-pagination"></div>
          </div>
          <div
            class="shadow-arrow shadow-left"
            :class="arrow.prev || eventsOngoing.items.length < 4 ? 'hide' : ''"
          ></div>
          <div
            class="shadow-arrow shadow-right"
            :class="arrow.next || eventsOngoing.items.length < 4 ? 'hide' : ''"
          ></div>
          <Icons
            name="arrow-left"
            class="arrow prev"
            :class="arrow.prev || eventsOngoing.items.length < 4 ? 'hide' : ''"
          />
          <Icons
            name="arrow-right"
            class="arrow next"
            :class="arrow.next || eventsOngoing.items.length < 4 ? 'hide' : ''"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../../../config";
import ModalLogin from "/components/modal/Login";
import ModalShare from "/components/modal/Share.vue";
import CardEleven from "/components/cards/CardEleven";
import QrcodeVue from "qrcode.vue";
import Link from "@/components/Link";
import ImageResponsive from "@/components/ImageResponsive";
import Icons from "@/components/Icons";

export default {
  async asyncData({ app, params, store, route, error }) {
    try {
      const response = await app.$axios.$get(
        `${config.EVENT_SERVICE_API}events/detail/${params.slug}`
      );
      const order = await app.$axios.$get(
        `${config.EVENT_SERVICE_API}events/attend/invoice?eventId=${response.data.id}&userId=${store.state.auth.user.id}`
      );

      store.commit("seo/SET_SEO", {
        title: `Checkout Event`,
        desc: response.data.title,
        image:
          response.data.thumbnail && response.data.thumbnail.large
            ? response.data.thumbnail.large
            : "dummy.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });

      return { response, order };
    } catch (e) {
      console.log(e);
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: {
    Icons,
    ImageResponsive,
    Link,
    ModalLogin,
    ModalShare,
    CardEleven,
    QrcodeVue,
  },
  data() {
    return {
      value: "https://example.com",
      size: 300,
      url: "",
      bgTicket: false,
      arrow: {
        prev: true,
        next: false,
      },
      swiperOptions: {
        freeMode: true,
        pagination: false,
        autoHeight: true,
        calculateHeight: true,
        // autoplay: {
        //   delay: 5000,
        // },
        navigation: {
          prevEl: ".prev",
          nextEl: ".next",
        },
        breakpoints: {
          1023: {
            slidesPerView: 3.5,
            spaceBetween: 16,
          },
          320: {
            slidesPerView: 1.2,
            spaceBetween: 16,
          },
        },
      },
    };
  },
  beforeRouteLeave(to, from, next) {
    this.modalLogin.hide();
    next();
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      event: (state) => {
        return state.events.dataDetailEvent.items;
      },
      eventsOngoing: (state) => {
        return state.events.eventDataOngoing;
      },
    }),
    formatDate() {
      let date = "";

      if (
        this.$moment(this.response.data.startDate).utc().format("DD MM YYYY") ==
        this.$moment(this.response.data.endDate).utc().format("DD MM YYYY")
      ) {
        date = this.$moment(this.response.data.startDate)
          .utc()
          .format("Do MMM YYYY");
      } else {
        date =
          this.$moment(this.response.data.startDate).utc().format("Do MMM") +
          " - " +
          this.$moment(this.response.data.endDate).utc().format("Do MMM YYYY");
      }
      return date;
    },
  },
  methods: {
    async initData() {
      await this.$store
        .dispatch("events/dataDetailEvent", this.$route.params.slug)
        .then(() => {
          let tes = [];
          for (const x in this.event.categories) {
            tes.push(this.event.categories[x].id);
          }
          let x = JSON.stringify(tes);
          this.categoriesRecommend = x.substr(1, x.length - 2);
          return response;
        })
        .catch((e) => {
          return e.response;
        });
      await this.$store.dispatch("events/dataEventOngoing");
    },
    onSwiperSlideChange() {
      this.arrow.next = this.mySwiper.isEnd;
      this.arrow.prev = this.mySwiper.isBeginning;
    },
    getPrice(price, discount) {
      let realPrice = (price / 100) * discount;
      if (realPrice === price || realPrice === 0) {
        return "GRATIS";
      } else {
        return this.changeToRupiah(realPrice);
      }
    },
    changeToRupiah(data) {
      if (!data) {
        data = 0;
      }
      let bilangan = data;
      let reverse = bilangan.toString().split("").reverse().join(""),
        ribuan = reverse.match(/\d{1,3}/g);
      ribuan = ribuan.join(".").split("").reverse().join("");
      return "Rp. " + ribuan;
    },
  },
  mounted() {
    this.initData();
    if (!this.$store.state.auth.loggedIn) {
      this.modalLogin.show();
    }
    this.url = window.location.href;
  },
};
</script>

<style lang="scss" scoped>
.content-main {
  padding: 32px 0 48px;
  .breadcrumbs {
    .container {
      display: flex;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 20px;
      a {
        font-size: 14px;
        color: #616161;
        display: flex;
        align-items: center;
        svg {
          width: 18px;
          height: fit-content;
          margin: 0 10px;
        }
        &.title-article {
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .buy-success {
    padding: 32px 0 48px;
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 20px;
        font-weight: 700;
        color: #051c2c;
        margin-bottom: 16px;
        @media only screen and (max-width: 1024px) {
          font-size: 20px;
          margin-bottom: 10px;
        }
        svg {
          width: 25px;
          height: auto;
          margin: 0;
        }
      }
      .desc {
        font-size: 14px;
        color: #787878;
        margin: 0;
        text-align: center;
        @media only screen and (max-width: 1024px) {
          font-size: 12px;
          text-align: center;
        }
        a {
          text-decoration: underline !important;
        }
      }
    }
  }
  .container {
    .description-event {
      padding-bottom: 50px;
      @media only screen and (max-width: 1024px) {
        padding-bottom: 32px;
      }
      .accordion-item {
        border-radius: 6px;
        overflow: hidden;
        @media only screen and (max-width: 1024px) {
          position: relative;
          z-index: 0;
        }
        &.first-item {
          margin-bottom: 16px;
        }
        .accordion-header {
          border: none;
        }
        .accordion-button {
          font-size: 20px;
          font-weight: 500;
          color: #051c2c;
          background: white;
          box-shadow: none;
        }
        .accordion-body {
          font-size: 14px;
          line-height: 28px;
          color: #051c2c;
          padding-top: 0;
        }
      }
    }
    .card-event {
      display: flex;
      background: #fafafa;
      box-shadow: 0px 0px 10px #e5e5e5;
      border-radius: 10px;
      margin-bottom: 48px;
      @media only screen and (max-width: 1024px) {
        margin-bottom: 24px;
        flex-direction: column;
      }
      .primary {
        width: 75%;
        display: flex;
        padding: 20px;
        @media only screen and (max-width: 1024px) {
          width: 100%;
          align-items: center;
          flex-direction: column;
        }
        &.fullWidth {
          width: 100%;
        }
        .thumbnail {
          width: 40%;
          @media only screen and (max-width: 1024px) {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
          }
          .image {
            @media only screen and (max-width: 1024px) {
              height: auto;
              width: 50%;
              border-radius: 6px;
              overflow: hidden;
            }
          }
          .copy-wrapper-mobile {
            display: none;
            @media only screen and (max-width: 1024px) {
              padding-left: 20px;
              display: block;
              width: 50%;
            }
            .category-wrapper {
              @media only screen and (max-width: 1024px) {
                display: flex;
                align-items: center;
              }
              .category {
                @media only screen and (max-width: 1024px) {
                  display: flex;
                  align-items: center;
                  color: #4a25aa;
                  margin-bottom: 5px;
                  white-space: nowrap;
                  font-size: 10px;
                }
                &:last-child {
                  span {
                    @media only screen and (max-width: 1024px) {
                      display: none;
                    }
                  }
                }
                span {
                  @media only screen and (max-width: 1024px) {
                    font-size: 12px;
                    padding: 0 4px;
                  }
                }
              }
            }
            .title {
              @media only screen and (max-width: 1024px) {
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                margin-bottom: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                color: #0c0b0d;
              }
            }
          }
        }
        .copy-wrapper {
          width: 60%;
          padding-left: 30px;
          @media only screen and (max-width: 1024px) {
            width: 100%;
            padding: 0;
          }
          .category-wrapper {
            display: flex;
            align-items: center;
            @media only screen and (max-width: 1024px) {
              display: none;
            }
            .category {
              display: flex;
              align-items: center;
              font-size: 16px;
              margin-bottom: 10px;
              color: #4a25aa;
              @media only screen and (max-width: 1024px) {
                font-size: 12px;
                margin-bottom: 5px;
                white-space: nowrap;
              }
              &:last-child {
                span {
                  display: none;
                }
              }
              span {
                padding: 0 8px;
                font-size: 12px;
                @media only screen and (max-width: 1024px) {
                  padding: 0 4px;
                }
              }
            }
          }
          .title {
            font-size: 32px;
            font-weight: 500;
            line-height: 40px;
            margin-bottom: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            color: #0c0b0d;
            @media only screen and (max-width: 1024px) {
              font-size: 16px;
              line-height: 18px;
              display: none;
            }
          }
          .box-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(1, 1fr);
            grid-column-gap: 10px;
            grid-row-gap: 8px;
            .box {
              display: flex;
              align-items: center;
              color: #616161;
              @media only screen and (max-width: 1024px) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              }
              .image {
                width: 20px;
                height: auto;
                margin-right: 10px;
              }
              .label {
                font-size: 10px;
                margin: 0;
                margin-bottom: 4px;
                color: #515151;
              }
              .desc-label {
                font-size: 14px;
                margin: 0;
                color: #0c0b0d;
                width: fit-content;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                @media only screen and (max-width: 1024px) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .secondary {
        width: 25%;
        border-left: 0.18em dashed #e5e5e5;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 20px;
        position: relative;
        text-align: center;
        @media only screen and (max-width: 1024px) {
          width: 100%;
          border-top: 0.18em dashed #f1f1f1;
          border-left: none;
        }
        &:before,
        &:after {
          content: "";
          position: absolute;
          display: block;
          width: 2em;
          height: 2em;
          background: #f1f1f1;
          border-radius: 50%;
          left: -1.1em;
          @media only screen and (max-width: 1024px) {
            top: -1.1em;
            left: unset;
          }
        }
        &:before {
          top: -0.8em;
          @media only screen and (max-width: 1024px) {
            top: -1.1em;
            left: -0.9em;
          }
        }
        &:after {
          bottom: -0.8em;
          @media only screen and (max-width: 1024px) {
            right: -0.9em;
            bottom: unset;
          }
        }
        .qrcode-wrapper {
          margin-bottom: 8px;
        }
        .text {
          font-size: 12px;
          margin: 0;
        }
      }
    }
    // .list-ticket {
    //   margin-bottom: 32px;
    //   .tickets {
    //     .card-ticket {
    //       position: relative;
    //       cursor: pointer;
    //       margin-bottom: 16px;
    //       &:last-child {
    //         margin: 0;
    //       }
    //       .bg-ticket {
    //         width: 100%;
    //         height: auto;
    //         &:hover {
    //           filter: drop-shadow(0px 0px 0 #4a25aa)
    //             drop-shadow(0px 0px 0 #4a25aa) drop-shadow(0px 0px 0 #4a25aa)
    //             drop-shadow(0px 0px 0 #4a25aa);
    //         }
    //         &.for-desktop {
    //           display: block;
    //           @media only screen and (max-width: 1024px) {
    //             display: none;
    //           }
    //         }
    //         &.for-mobile {
    //           display: none;
    //           @media only screen and (max-width: 1024px) {
    //             display: block;
    //           }
    //         }
    //       }
    //       .copy-wrapper {
    //         position: absolute;
    //         top: 50%;
    //         right: 0;
    //         transform: translateY(-50%);
    //         width: 90%;
    //         display: flex;
    //         flex-direction: column;
    //         padding: 20px 40px;
    //         pointer-events: none;
    //         .primary {
    //           display: flex;
    //           justify-content: space-between;
    //           align-items: center;
    //           padding-bottom: 20px;
    //           border-bottom: 1px solid lightgray;
    //           @media only screen and (max-width: 1024px) {
    //             padding-bottom: 5px;
    //           }
    //           .ticket-type {
    //             font-size: 24px;
    //             font-weight: 500;
    //             color: #333333;
    //             margin: 0;
    //             @media only screen and (max-width: 1024px) {
    //               font-size: 12px;
    //               width: 40%;
    //             }
    //           }
    //           .exp {
    //             font-size: 16px;
    //             color: #4f4f4f;
    //             margin: 0;
    //             @media only screen and (max-width: 1024px) {
    //               font-size: 10px;
    //               width: 60%;
    //               text-align: right;
    //             }
    //             br {
    //               display: none;
    //               @media only screen and (max-width: 1024px) {
    //                 display: block;
    //               }
    //             }
    //           }
    //         }
    //         .secondary {
    //           display: flex;
    //           justify-content: space-between;
    //           align-items: center;
    //           padding-top: 20px;
    //           @media only screen and (max-width: 1024px) {
    //             padding-top: 5px;
    //           }
    //           .desc {
    //             font-size: 14px;
    //             color: #333333;
    //             margin: 0;
    //             @media only screen and (max-width: 1024px) {
    //               font-size: 10px;
    //               overflow: hidden;
    //               text-overflow: ellipsis;
    //               -webkit-line-clamp: 2;
    //               display: -webkit-box;
    //               -webkit-box-orient: vertical;
    //             }
    //           }
    //           .price-discount {
    //             display: flex;
    //             justify-content: space-between;
    //             align-items: center;
    //             .discount {
    //               font-size: 16px;
    //               color: #828282;
    //               text-decoration: line-through;
    //               margin: 0 20px 0 0;
    //               @media only screen and (max-width: 1024px) {
    //                 font-size: 10px;
    //               }
    //             }
    //             .price {
    //               font-size: 32px;
    //               color: #333333;
    //               margin: 0;
    //               @media only screen and (max-width: 1024px) {
    //                 font-size: 14px;
    //               }
    //             }
    //           }
    //           .sold-out {
    //             display: none;
    //             // @media only screen and (max-width: 1024px) {
    //             //   font-size: 10px;
    //             //   padding: 4px 10px;
    //             //   display: flex;
    //             //   align-items: center;
    //             //   justify-content: center;
    //             //   border-radius: 4px;
    //             //   background: #e0e0e0;
    //             //   color: #9e9e9e;
    //             // }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    .recommend {
      .title {
        font-size: 16px;
        font-weight: 500;
        color: #051c2c;
        margin-bottom: 8px;
        @media only screen and (max-width: 1024px) {
          font-size: 14px;
          margin-bottom: 4px;
        }
      }
      .desc {
        font-size: 12px;
        color: #616161;
        margin-bottom: 24px;
        @media only screen and (max-width: 1024px) {
          font-size: 10px;
          margin-bottom: 16px;
        }
      }
      .shadow-arrow {
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100px;
        height: 100%;
        padding: 0;
        background: rgb(0, 0, 0);
        z-index: 2;
        opacity: 1;
        transition: ease-in-out 0.5s;
        @media only screen and (max-width: 1024px) {
          display: none;
        }

        &.shadow-left {
          left: 0;
          background: linear-gradient(
            to left,
            hsla(0, 0%, 100%, 0),
            rgba(241, 241, 241, 1)
          );
        }

        &.shadow-right {
          right: 0;
          background: linear-gradient(
            to right,
            hsla(0, 0%, 100%, 0),
            rgba(241, 241, 241, 1)
          );
        }

        &.hide {
          opacity: 0;
          z-index: 0;
        }
      }

      .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        width: auto;
        @media only screen and (max-width: 1024px) {
          display: none;
        }

        &.prev {
          left: 0;
        }

        &.next {
          right: 0;
        }

        &.hide {
          display: none;
        }
      }

      .swiper-container {
        @media only screen and (max-width: 1024px) {
          padding: 20px;
          margin: -20px;
        }
      }
    }
  }
}
</style>

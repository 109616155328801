var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-main"},[_c('ModalLogin',{attrs:{"redirect":_vm.$route.path},on:{"registeredModal":function($event){_vm.modalLogin = $event}}}),_vm._v(" "),_c('ModalShare',{on:{"registeredModal":function($event){_vm.modalShare = $event}}}),_vm._v(" "),_c('Breadcrumbs'),_vm._v(" "),_c('section',{staticClass:"banner"},[(_vm.response.data.banner && _vm.response.data.banner.large)?_c('div',{staticClass:"banner-wrapper container"},[_c('ImageResponsiveBanner',{attrs:{"imageUrl":_vm.response.data.banner && _vm.response.data.banner.large
            ? _vm.response.data.banner.large
            : 'https://narasi.tv/storage/images/dummy.png',"imageUrlMobile":_vm.response.data.thumbnail && _vm.response.data.thumbnail.medium
            ? _vm.response.data.thumbnail.medium
            : 'https://narasi.tv/storage/images/dummy.png',"width":"1200","height":"279","widthMobile":"780","heightMobile":"350","fromUrl":true}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"copy-wrapper container"},[_c('div',{staticClass:"copy-detail"},[_c('div',{staticClass:"categories"},_vm._l((_vm.response.data.categories.length > 0
              ? _vm.response.data.categories
              : _vm.response.data.categories.length > 3
              ? _vm.response.data.categories.slice(0, 3)
              : [
                  {
                    id: '1',
                    title: 'Uncategorized',
                  },
                ]),function(category){return _c('div',{key:category.id,staticClass:"category"},[_vm._v("\n            "+_vm._s(category.title)+"\n            "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.response.data.categories.length > 0),expression:"response.data.categories.length > 0"}]},[_vm._v("•")])])}),0),_vm._v(" "),_c('div',{staticClass:"title"},[_vm._v("\n          "+_vm._s(_vm.response.data.title)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"desc-wrapper"},[_c('div',{staticClass:"location box"},[_c('Icons',{attrs:{"name":"location","color":"#616161"}}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.response.data.location))])],1),_vm._v(" "),_c('div',{staticClass:"schedule box"},[_c('Icons',{attrs:{"name":"calendar","color":"#616161"}}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("\n              "+_vm._s(_vm.formatSchedule)+"\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"organizer box"},[_c('Icons',{attrs:{"name":"organizer","color":"#616161"}}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.response.data.organizer))])],1)]),_vm._v(" "),_c('div',{staticClass:"share-tiket"},[_c('span',{staticClass:"share-wrapper share-video btn-share-event d-flex",on:{"click":function($event){return _vm.modalShare.show()}}},[_c('Icons',{staticClass:"mr-2 pe-none",attrs:{"name":"bagikan","color":"#4A25AA"}}),_vm._v(" "),_c('div',{staticClass:"share pe-none"},[_vm._v("Bagikan Event")])],1),_vm._v(" "),(
              _vm.attendDataStatus.data &&
              !_vm.returnCopyRegisStatus &&
              _vm.$store.state.auth.loggedIn
            )?_c('Link',{staticClass:"lihat-tiket",attrs:{"to":{
              name: 'event-organizer-slug-ticket',
              params: {
                organizer: _vm.response.data.organizerSlug,
                slug: _vm.response.data.slug,
              },
            }}},[_c('Icons',{attrs:{"name":"tiket-event"}}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("Lihat Tiket")])],1):_vm._e(),_vm._v(" "),(_vm.returnCopyRegisStatus)?_c('p',{staticClass:"event-info"},[_vm._v("\n            "+_vm._s(_vm.returnCopyRegisStatus)+"\n          ")]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"copy-ticket",class:{ hideMobile: !_vm.ticketMobile }},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"price-discount-wrapper"},[_c('p',{staticClass:"discount"},[_vm._v(_vm._s(_vm.changeToRupiah(_vm.response.data.price)))]),_vm._v(" "),_c('h2',{staticClass:"price"},[_vm._v(_vm._s(_vm.getPrice))])]),_vm._v(" "),_c('div',[_c('a',{staticClass:"button-livestream",class:{ disable: _vm.disableButton },attrs:{"href":_vm.$route.params.slug ==
                'the-beauty-of-giving-project-goes-to-campus'
                  ? 'https://docs.google.com/forms/d/e/1FAIpQLSdDxs428bvBc2Ow58LlB6uBMQqCfLfuFzXReFfJOkGYp2144Q/viewform'
                  : _vm.attendDataStatus.data &&
                    _vm.response.data.link &&
                    _vm.$moment().utc() > _vm.$moment(_vm.response.data.startDate).utc()
                  ? _vm.response.data.link
                  : 'javascript:void(0)',"target":_vm.$route.params.slug ==
                'the-beauty-of-giving-project-goes-to-campus'
                  ? '_blank'
                  : '',"id":_vm.disableButton ? null : 'clickDaftarEvent'},on:{"click":function($event){_vm.$route.params.slug ==
                'the-beauty-of-giving-project-goes-to-campus'
                  ? ''
                  : _vm.attendDataStatus.data
                  ? ''
                  : _vm.response.data.typeform && _vm.$store.state.auth.loggedIn
                  ? _vm.redirect(
                      `/event/${_vm.$route.params.organizer}/${_vm.$route.params.slug}/survey`
                    )
                  : _vm.checkout()}}},[_vm._v("\n              "+_vm._s(_vm.btnCopy)+"\n            ")])])]),_vm._v(" "),(_vm.returnCopyRegisStatus)?_c('p',{staticClass:"event-info"},[_vm._v("\n          "+_vm._s(_vm.returnCopyRegisStatus)+"\n        ")]):_vm._e(),_vm._v(" "),(
            _vm.attendDataStatus.data &&
            !_vm.returnCopyRegisStatus &&
            _vm.$store.state.auth.loggedIn
          )?_c('Link',{staticClass:"lihat-tiket",attrs:{"to":{
            name: 'event-organizer-slug-ticket',
            params: {
              organizer: _vm.response.data.organizerSlug,
              slug: _vm.response.data.slug,
            },
          }}},[_c('Icons',{attrs:{"name":"tiket-event"}}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("Lihat Tiket")])],1):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"container"},[_c('section',{staticClass:"description-event"},[_c('div',{staticClass:"accordion",attrs:{"id":"accordionExample"}},[_c('div',{staticClass:"accordion-item",class:{ 'first-item': _vm.response.data.tnc !== '' }},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"accordion-collapse collapse show",attrs:{"id":"collapseOne","aria-labelledby":"headingOne"}},[_c('div',{staticClass:"accordion-body",domProps:{"innerHTML":_vm._s(_vm.response.data.description)}})])]),_vm._v(" "),(_vm.response.data.tnc !== '')?_c('div',{staticClass:"accordion-item"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"accordion-collapse collapse show",attrs:{"id":"collapseTwo","aria-labelledby":"headingTwo"}},[_c('div',{staticClass:"accordion-body",domProps:{"innerHTML":_vm._s(_vm.response.data.tnc)}})])]):_vm._e()])]),_vm._v(" "),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.eventsRecommend.items && _vm.eventsRecommend.items.length > 0),expression:"eventsRecommend.items && eventsRecommend.items.length > 0"}],staticClass:"recommend-event row"},[_vm._m(2),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"position-relative"},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiper",value:(_vm.swiperOptions),expression:"swiperOptions",arg:"mySwiper"}]},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.eventsRecommend.items),function(card,index){return _c('CardEleven',{key:index,staticClass:"swiper-slide",attrs:{"image":card.thumbnail && card.thumbnail.large
                    ? card.thumbnail.large
                    : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"ticket":card.ticketType,"categories":card.categories,"location":card.location,"title":card.title,"slug":card.slug,"description":card.description,"datetime":card.startDate,"price":card.price,"discount":card.discount,"to":{
                  name: 'event-organizer-slug',
                  params: {
                    organizer: card.organizer,
                    slug: card.slug,
                  },
                },"startEvent":card.startDate,"endEvent":card.endDate,"startRegis":card.startRegistrationDate,"endRegis":card.endRegistrationDate}})}),1),_vm._v(" "),_c('div',{staticClass:"swiper-pagination"})]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.eventsRecommend.items && _vm.eventsRecommend.items.length < 5),expression:"eventsRecommend.items && eventsRecommend.items.length < 5"}],staticClass:"shadow-arrow shadow-left prev"},[_c('Icons',{staticClass:"arrow",attrs:{"name":"arrow-left"}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.eventsRecommend.items && _vm.eventsRecommend.items.length < 5),expression:"eventsRecommend.items && eventsRecommend.items.length < 5"}],staticClass:"shadow-arrow shadow-right next"},[_c('Icons',{staticClass:"arrow",attrs:{"name":"arrow-right"}})],1)])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"accordion-header",attrs:{"id":"headingOne"}},[_c('button',{staticClass:"accordion-button",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#collapseOne","aria-expanded":"true","aria-controls":"collapseOne"}},[_vm._v("\n              Deskripsi\n            ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"accordion-header",attrs:{"id":"headingTwo"}},[_c('button',{staticClass:"accordion-button",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#collapseTwo","aria-expanded":"true","aria-controls":"collapseTwo"}},[_vm._v("\n              Syarat & Ketentuan Berlaku\n            ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"title-wrapper"},[_c('div',{staticClass:"titleContent"},[_c('p',{staticClass:"title m-0"},[_vm._v("MUNGKIN KAMU SUKA")])])])])
}]

export { render, staticRenderFns }
<template>
  <div class="content-main">
    <!-- <ModalGoers
      :data="response.data"
      :src="response.data.externalUrlEvent"
      @registeredModal="modalGoers = $event"
    >
      <button @click="modalGoers.hide()" type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
    </ModalGoers> -->
    <ModalLogin
      :redirect="$route.path"
      @registeredModal="modalLogin = $event"
    />
    <ModalShare @registeredModal="modalShare = $event" />
    <Breadcrumbs />
    <section class="banner">
      <div
        v-if="response.data.banner && response.data.banner.large"
        class="banner-wrapper container"
      >
        <ImageResponsiveBanner
          :imageUrl="
            response.data.banner && response.data.banner.large
              ? response.data.banner.large
              : 'https://narasi.tv/storage/images/dummy.png'
          "
          :imageUrlMobile="
            response.data.thumbnail && response.data.thumbnail.medium
              ? response.data.thumbnail.medium
              : 'https://narasi.tv/storage/images/dummy.png'
          "
          width="1200"
          height="279"
          widthMobile="780"
          heightMobile="350"
          :fromUrl="true"
        />
      </div>
      <div class="copy-wrapper container">
        <div class="copy-detail">
          <div class="categories">
            <div
              v-for="category in response.data.categories.length > 0
                ? response.data.categories
                : response.data.categories.length > 3
                ? response.data.categories.slice(0, 3)
                : [
                    {
                      id: '1',
                      title: 'Uncategorized',
                    },
                  ]"
              :key="category.id"
              class="category"
            >
              {{ category.title }}
              <span v-show="response.data.categories.length > 0">&bull;</span>
            </div>
          </div>
          <div class="title">
            {{ response.data.title }}
          </div>
          <div class="desc-wrapper">
            <div class="location box">
              <Icons name="location" color="#616161" />
              <p class="text">{{ response.data.location }}</p>
            </div>
            <div class="schedule box">
              <Icons name="calendar" color="#616161" />
              <p class="text">
                {{ formatSchedule }}
              </p>
            </div>
            <div class="organizer box">
              <Icons name="organizer" color="#616161" />
              <p class="text">{{ response.data.organizer }}</p>
            </div>
          </div>
          <div class="share-tiket">
            <span
              class="share-wrapper share-video btn-share-event d-flex"
              @click="modalShare.show()"
            >
              <Icons name="bagikan" color="#4A25AA" class="mr-2 pe-none" />
              <div class="share pe-none">Bagikan Event</div>
            </span>
            <Link
              v-if="
                attendDataStatus.data &&
                !returnCopyRegisStatus &&
                $store.state.auth.loggedIn
              "
              :to="{
                name: 'event-organizer-slug-ticket',
                params: {
                  organizer: response.data.organizerSlug,
                  slug: response.data.slug,
                },
              }"
              class="lihat-tiket"
            >
              <Icons name="tiket-event" />
              <p class="text">Lihat Tiket</p>
            </Link>
            <p class="event-info" v-if="returnCopyRegisStatus">
              {{ returnCopyRegisStatus }}
            </p>
          </div>
        </div>
        <div class="copy-ticket" :class="{ hideMobile: !ticketMobile }">
          <div class="card">
            <div class="price-discount-wrapper">
              <p class="discount">{{ changeToRupiah(response.data.price) }}</p>
              <h2 class="price">{{ getPrice }}</h2>
            </div>
            <div>
              <a
                :href="
                  $route.params.slug ==
                  'the-beauty-of-giving-project-goes-to-campus'
                    ? 'https://docs.google.com/forms/d/e/1FAIpQLSdDxs428bvBc2Ow58LlB6uBMQqCfLfuFzXReFfJOkGYp2144Q/viewform'
                    : attendDataStatus.data &&
                      response.data.link &&
                      $moment().utc() > $moment(response.data.startDate).utc()
                    ? response.data.link
                    : 'javascript:void(0)'
                "
                :target="
                  $route.params.slug ==
                  'the-beauty-of-giving-project-goes-to-campus'
                    ? '_blank'
                    : ''
                "
                :id="disableButton ? null : 'clickDaftarEvent'"
                class="button-livestream"
                :class="{ disable: disableButton }"
                @click="
                  $route.params.slug ==
                  'the-beauty-of-giving-project-goes-to-campus'
                    ? ''
                    : attendDataStatus.data
                    ? ''
                    : response.data.typeform && $store.state.auth.loggedIn
                    ? redirect(
                        `/event/${$route.params.organizer}/${$route.params.slug}/survey`
                      )
                    : checkout()
                "
              >
                {{ btnCopy }}
              </a>
            </div>
          </div>
          <p class="event-info" v-if="returnCopyRegisStatus">
            {{ returnCopyRegisStatus }}
          </p>
          <Link
            v-if="
              attendDataStatus.data &&
              !returnCopyRegisStatus &&
              $store.state.auth.loggedIn
            "
            :to="{
              name: 'event-organizer-slug-ticket',
              params: {
                organizer: response.data.organizerSlug,
                slug: response.data.slug,
              },
            }"
            class="lihat-tiket"
          >
            <Icons name="tiket-event" />
            <p class="text">Lihat Tiket</p>
          </Link>
        </div>
      </div>
    </section>
    <div class="container">
      <section class="description-event">
        <div class="accordion" id="accordionExample">
          <div
            class="accordion-item"
            :class="{ 'first-item': response.data.tnc !== '' }"
          >
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Deskripsi
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
            >
              <div
                class="accordion-body"
                v-html="response.data.description"
              ></div>
            </div>
          </div>
          <div v-if="response.data.tnc !== ''" class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                Syarat & Ketentuan Berlaku
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse show"
              aria-labelledby="headingTwo"
            >
              <div class="accordion-body" v-html="response.data.tnc"></div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="recommend-event row"
        v-show="eventsRecommend.items && eventsRecommend.items.length > 0"
      >
        <div class="container">
          <div class="title-wrapper">
            <div class="titleContent">
              <p class="title m-0">MUNGKIN KAMU SUKA</p>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="position-relative">
            <div v-swiper:mySwiper="swiperOptions">
              <div class="swiper-wrapper">
                <CardEleven
                  class="swiper-slide"
                  v-for="(card, index) in eventsRecommend.items"
                  :key="index"
                  :image="
                    card.thumbnail && card.thumbnail.large
                      ? card.thumbnail.large
                      : 'https://narasi.tv/storage/images/dummy.png'
                  "
                  :imageFromUrl="true"
                  :ticket="card.ticketType"
                  :categories="card.categories"
                  :location="card.location"
                  :title="card.title"
                  :slug="card.slug"
                  :description="card.description"
                  :datetime="card.startDate"
                  :price="card.price"
                  :discount="card.discount"
                  :to="{
                    name: 'event-organizer-slug',
                    params: {
                      organizer: card.organizer,
                      slug: card.slug,
                    },
                  }"
                  :startEvent="card.startDate"
                  :endEvent="card.endDate"
                  :startRegis="card.startRegistrationDate"
                  :endRegis="card.endRegistrationDate"
                />
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div
              class="shadow-arrow shadow-left prev"
              v-show="eventsRecommend.items && eventsRecommend.items.length < 5"
            >
              <Icons name="arrow-left" class="arrow" />
            </div>
            <div
              class="shadow-arrow shadow-right next"
              v-show="eventsRecommend.items && eventsRecommend.items.length < 5"
            >
              <Icons name="arrow-right" class="arrow" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../../../config";
import CardEleven from "/components/cards/CardEleven";
import ModalLogin from "/components/modal/Login";
import ModalShare from "/components/modal/Share.vue";
import Breadcrumbs from "@/components/Breadcrumbs";
import ImageResponsiveBanner from "@/components/ImageResponsiveBanner";
import Icons from "@/components/Icons";
import Link from "@/components/Link";

export default {
  async asyncData({ app, params, $axios, store, route, error, redirect }) {

    try {
      const response = await app.$axios.$get(
        `${config.EVENT_SERVICE_API}events/detail/${params.slug}`
      );

      let tes = [];
      for (const x in response.data.categories) {
        tes.push(response.data.categories[x].id);
      }
      let x = JSON.stringify(tes);
      const categoriesRecommend = x.substr(1, x.length - 2);

      store.commit("seo/SET_SEO", {
        title: response.data.title,
        desc: response.data.description
          ? response.data.description
          : response.data.title,
        image:
          response.data.thumbnail && response.data.thumbnail.large
            ? response.data.thumbnail.large
            : "dummy.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
      return { response, categoriesRecommend };
    } catch (e) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: {
    Link,
    Icons,
    ImageResponsiveBanner,
    Breadcrumbs,
    CardEleven,
    ModalLogin,
    ModalShare,
  },
  data() {
    return {
      disableButton: true,
      btnCopy: "DAFTAR EVENT",
      ticketMobile: true,
      url: "",
      attendDataStatus: {
        data: false,
        message: "Silahkan mendaftar di event ini!",
        status: 200,
      },
      categoriesRecommend: "",
      swiperOptions: {
        freeMode: true,
        pagination: false,
        autoHeight: true,
        calculateHeight: true,
        // autoplay: {
        //   delay: 5000,
        // },
        navigation: {
          prevEl: ".prev",
          nextEl: ".next",
        },
        breakpoints: {
          1023: {
            slidesPerView: 3.5,
            spaceBetween: 16,
          },
          320: {
            slidesPerView: 1.2,
            spaceBetween: 16,
          },
        },
      },
    };
  },
  beforeRouteLeave(to, from, next) {
    this.modalLogin.hide();
    this.modalShare.hide();
    next();
  },
  head() {
    return this.headReturn;
  },
  created() {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      this.modalLogin.hide();
      this.modalShare.hide();
      next(true);
    });
    this.$once("hook:destroyed", () => {
      unregisterRouterGuard();
    });
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      eventsRecommend: (state) => {
        return state.events.eventDataRecommend;
      },
      loginStatus: (state) => {
        return state.account.loggedIn;
      },
    }),
    getPrice() {
      let price = this.response.data.price ? this.response.data.price : 0;
      let discountInPercent = this.response.data.discount
        ? this.response.data.discount
        : 100;
      let realPrice = (price / 100) * discountInPercent;
      if (realPrice === price || realPrice === 0) {
        return "GRATIS";
      } else {
        return this.changeToRupiah(realPrice);
      }
    },
    returnCopyRegisStatus() {
      const compareDate = this.$moment().utc();
      const startDate = this.$moment(this.response.data.startDate)
        .utc()
        .format();
      const endDate = this.$moment(this.response.data.endDate).utc().format();
      const startRegistrationDate = this.$moment(
        this.response.data.startRegistrationDate
      )
        .utc()
        .format();
      const endRegistrationDate = this.$moment(
        this.response.data.endRegistrationDate
      )
        .utc()
        .format();

      if (this.response.data.tickets[0].stock == 0) {
        this.disableButton = true;
        this.btnCopy = "TIKET HABIS";
        return false;
      } else if (this.attendDataStatus.data) {
        if (
          startDate < this.$moment().utc().local().format() &&
          endDate > this.$moment().utc().local().format()
        ) {
          this.disableButton = false;
          this.btnCopy = "MASUK EVENT";
          return false;
          // return "Kamu Sudah Terdaftar Di Event Ini, Silahkan Akses Untuk Menikmati Event";
        } else {
          this.disableButton = true;
          this.btnCopy = "BELUM MULAI";
          return false;
          // return "Kamu Sudah Terdaftar Di Event Ini, Silahkan Tandai Kalendermu Agar Tidak Terlewat";
        }
      } else if (endDate < this.$moment().utc().local().format()) {
        this.disableButton = true;
        if (this.getPrice !== "GRATIS") {
          this.btnCopy = "BELI TIKET";
        } else {
          this.btnCopy = "DAFTAR EVENT";
        }
        return "Maaf, Event Ini Telah Selesai. Silahkan Ikuti Event Lain Yang Tidak Kalah Menarik";
      } else if (endRegistrationDate < this.$moment().utc().local().format()) {
        this.disableButton = true;
        if (this.getPrice !== "GRATIS") {
          this.btnCopy = "BELI TIKET";
        } else {
          this.btnCopy = "DAFTAR EVENT";
        }
        return "Registrasi event ini telah ditutup";
      } else if (
        startRegistrationDate > this.$moment().utc().local().format()
      ) {
        this.disableButton = true;
        if (this.getPrice !== "GRATIS") {
          this.btnCopy = "BELI TIKET";
        } else {
          this.btnCopy = "DAFTAR EVENT";
        }
        return "Registrasi event ini belum dibuka, silakan cek tanggal di deskripsi";
      } else {
        this.disableButton = false;
        return "Registrasi event ini telah dibuka";
      }
    },
    formatSchedule() {
      let schedule = "";
      if (
        this.$moment(this.response.data.startDate).utc().format("DD MM YYYY") ==
        this.$moment(this.response.data.endDate).utc().format("DD MM YYYY")
      ) {
        schedule =
          this.$moment(this.response.data.startDate).utc().format("LLLL") +
          " - " +
          this.$moment(this.response.data.endDate).utc().format("HH mm");
      } else {
        schedule =
          this.$moment(this.response.data.startDate).utc().format("LLLL") +
          " - " +
          this.$moment(this.response.data.endDate).utc().format("LLLL");
      }
      return schedule;
    },
  },
  methods: {
    redirect(link) {
      window.location.href = this.$router.resolve(link).href;
    },
    changeToRupiah(data) {
      if (!data) {
        data = 0;
      }
      let bilangan = data;
      let reverse = bilangan.toString().split("").reverse().join(""),
        ribuan = reverse.match(/\d{1,3}/g);
      ribuan = ribuan.join(".").split("").reverse().join("");
      return "Rp. " + ribuan;
    },
    async initData() {
      await this.$store.dispatch("events/dataEventRecommend", {
        categories: this.categoriesRecommend,
        eventId: this.response.data.id,
      });
    },
    async attendStatus() {
      await this.$axios
        .$post(`${config.EVENT_SERVICE_API}events/attend/status`, {
          userId: this.$store.state.auth.user.id,
          eventId: this.response.data.id,
        })
        .then((attend) => {
          let startDate = this.$moment(this.response.data.startDate)
            .utc()
            .valueOf();
          let endDate = this.$moment(this.response.data.endDate)
            .utc()
            .valueOf();
          if (startDate >= endDate && endDate <= startDate) {
            this.attendDataStatus = {
              data: true,
              message: null,
              status: 200,
            };
          } else {
            this.attendDataStatus = attend;
          }
        })
        .catch((e) => {
          this.attendDataStatus = attend;
        });
    },
    async checkout() {
      // jika ada externalUrlEvent maka akan langsung open new tab ke url target
      if (this.response.data.externalUrlEvent) {
        // return this.modalGoers.show();
        return window.open(
          this.response.data.externalUrlEvent,
          "_blank",
          "noopener"
        );
      }

      if (!this.$store.state.auth.loggedIn) {
        this.modalLogin.show();
      } else {
        let rawTicket =
          this.response.data.tickets.length > 0
            ? this.response.data.tickets[0]
            : JSON.stringify({});
        let rawData = this.response.data;
        delete rawData.tickets;
        // delete rawData.categories;

        let payload = {
          userId: this.$store.state.auth.user.id,
          email: this.$store.state.auth.user.email,
          fullname: this.$store.state.auth.user.fullname,
          eventId: this.response.data.id,
          ticketRaw: rawTicket,
          eventRaw: rawData,
        };

        await this.$axios
          .post(`${config.EVENT_SERVICE_API}events/attend`, payload)
          .then((res) => {
            if (res.data.message == "Ticket sudah habis.") {
              this.$toast.error(
                "Maaf, pendaftaran gagal karena tiket telah habis"
              );
            } else if (res.data.message == "events not found") {
              this.$toast.error("Event tidak ditemukan");
            } else {
              this.$toast.success(`Sukses, ${res.data.message}`);
              window.location.href = this.$router.resolve(
                `/event/${this.response.data.organizerSlug}/${this.response.data.slug}/checkout`
              ).href;
            }
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message);
            setTimeout(() => {
              this.$router.go();
            }, 1000);
          });
      }
    },
    handleScroll() {
      if (
        window.innerHeight + window.scrollY - 500 >
        document.body.offsetHeight
      ) {
        this.ticketMobile = false;
      } else {
        this.ticketMobile = true;
      }
    },
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.initData();
    if (this.$store.state.auth.loggedIn) {
      this.attendStatus();
    } else {
      if (process.env.browser) {
        this.modalLogin.show();
      }
    }
    this.url = window.location.href;
  },
};
</script>

<style lang="scss" scoped>
.content-main {
  img {
    width: 100%;
  }
  .banner {
    background: #fafafa;
    .banner-wrapper {
      position: relative;
      @media only screen and (max-width: 1024px) {
        padding: 0;
      }
      .image {
        width: 100%;
        height: auto;
      }
    }
    .copy-wrapper {
      z-index: 2;
      position: relative;
      display: flex;
      padding-top: 24px;
      padding-bottom: 32px;
      justify-content: space-between;
      .copy-detail {
        width: 80%;
        @media only screen and (max-width: 1024px) {
          width: 100%;
        }
        .categories {
          display: flex;
          align-items: center;
          @media only screen and (max-width: 1024px) {
            flex-wrap: wrap;
            margin: 24px 0 8px;
          }
          .category {
            font-size: 16px;
            color: #4a25aa;
            margin-bottom: 8px;
            @media only screen and (max-width: 1024px) {
              font-size: 12px;
              margin: 0;
              white-space: nowrap;
            }
            &:last-child {
              span {
                display: none;
              }
            }
            span {
              font-size: 12px;
              padding: 0 8px;
              @media only screen and (max-width: 1024px) {
                padding: 0 6px;
              }
            }
          }
        }
        .title {
          font-size: 32px;
          font-weight: 500;
          margin-bottom: 10px;
          color: #0c0b0d;
          @media only screen and (max-width: 1024px) {
            font-size: 20px;
            margin-bottom: 8px;
          }
        }
        .desc-wrapper {
          margin-bottom: 16px;
          .box {
            display: flex;
            margin-bottom: 10px;
            svg {
              width: auto;
              height: 20px;
              margin-right: 14px;
            }
            .text {
              font-size: 14px;
              color: #051c2c;
              margin: 0;
            }
          }
        }
        .share-tiket {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .share-wrapper {
            cursor: pointer;
            width: fit-content;
            align-items: center;
            color: #4a25aa;
            &:hover {
              .share {
                color: #4a25aa;
              }
            }

            svg {
              width: 16px;
              height: 16px;
              margin-right: 10px;
            }

            .share {
              text-decoration: underline !important;
              font-size: 12px;
              font-weight: 500;
            }
          }
          .lihat-tiket {
            display: flex;
            align-items: center;
            align-self: center;
            cursor: pointer;
            @media only screen and (min-width: 1024px) {
              display: none;
            }
            svg {
              width: 10px;
              height: auto;
              margin-right: 8px;
            }
            .text {
              font-size: 16px;
              font-weight: 500;
              color: #4a25aa;
              text-decoration: underline;
              margin: 0;
              @media only screen and (max-width: 1024px) {
                font-size: 14px;
              }
            }
          }
          .event-info {
            display: none;
            @media only screen and (max-width: 1024px) {
              display: block;
              font-size: 10px;
              color: #616161;
              text-align: center;
              width: 55%;
              text-align: right;
              margin: 0;
            }
          }
        }
      }
      .copy-ticket {
        // width: 20%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        @media only screen and (max-width: 1024px) {
          position: fixed;
          left: 0;
          bottom: 0;
          width: 100%;
        }
        &.hideMobile {
          @media only screen and (max-width: 1024px) {
            display: none;
          }
        }
        .card {
          background: #fafafa;
          border-radius: 10px;
          padding: 25px;
          box-shadow: 0px 6px 10px rgb(5 28 44 / 16%);
          margin-bottom: 20px;
          @media only screen and (max-width: 1024px) {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 80px;
            margin: 0;
            padding: 15px 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-radius: 0;
          }
          .price-discount-wrapper {
            .discount {
              font-size: 14px;
              color: #757575;
              text-decoration: line-through;
              margin-bottom: 16px;
              @media only screen and (max-width: 1024px) {
                font-size: 12px;
                margin-bottom: 8px;
              }
            }
            .price {
              font-size: 32px;
              font-weight: 500;
              color: #34128c;
              margin-bottom: 16px;
              @media only screen and (max-width: 1024px) {
                font-size: 24px;
                margin-bottom: 8px;
              }
            }
          }
          .button-livestream {
            width: fit-content;
            padding: 0 30px;
            height: 50px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 700;
            color: #fafafa;
            background: #4a25aa;
            border-radius: 4px;
            cursor: pointer;
            @media only screen and (max-width: 1024px) {
              font-size: 12px;
              height: 40px;
            }

            &:hover {
              color: #fafafa !important;
            }

            &.disable {
              background: #e0e0e0;
              color: #9e9e9e;
              pointer-events: none;
            }
          }
        }
        .event-info {
          font-size: 10px;
          color: #616161;
          text-align: center;
          white-space: nowrap;
          @media only screen and (max-width: 1024px) {
            display: none;
          }
        }
        .lihat-tiket {
          display: flex;
          align-items: center;
          align-self: center;
          cursor: pointer;
          @media only screen and (max-width: 1024px) {
            display: none;
          }
          svg {
            width: 16px;
            height: auto;
            margin-right: 10px;
          }
          .text {
            font-size: 16px;
            font-weight: 500;
            color: #4a25aa;
            text-decoration: underline;
            margin: 0;
          }
        }
      }
    }
  }

  .description-event {
    padding: 50px 0;
    @media only screen and (max-width: 1024px) {
      padding: 32px 0;
    }
    .accordion-item {
      border-radius: 6px;
      overflow: hidden;
      @media only screen and (max-width: 1024px) {
        position: relative;
        z-index: 0;
      }
      &.first-item {
        margin-bottom: 16px;
      }
      .accordion-button {
        font-size: 20px;
        color: #051c2c;
      }
      .accordion-body {
        font-size: 14px;
        line-height: 28px;
        color: #051c2c;
      }
    }
  }

  .recommend-event {
    position: relative;
    margin-bottom: 48px;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 32px;
    }

    .container {
      .title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        @media only screen and (max-width: 1024px) {
          margin-bottom: 16px;
        }

        .titleContent {
          @media only screen and (max-width: 1024px) {
            line-height: 16px;
          }

          .title {
            color: #4a25aa;
            font-size: 20px;
            font-weight: 500;
            padding-left: 30px;
            border-left: 10px solid #ffe900;
            @media only screen and (max-width: 1024px) {
              font-size: 16px;
              padding-left: 10px;
              border-width: 5px;
            }
          }
        }

        .see-all {
          font-size: 16px;
          font-weight: bold;
          text-decoration: underline !important;
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
        }
      }

      .shadow-arrow {
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100px;
        height: 100%;
        padding: 0;
        background: rgb(0, 0, 0);
        z-index: 2;
        opacity: 1;
        transition: ease-in-out 0.5s;
        @media only screen and (max-width: 1024px) {
          display: none;
        }

        svg {
          pointer-events: visible;
        }

        &.shadow-left {
          left: 0;
          background: linear-gradient(
            to left,
            hsla(0, 0%, 100%, 0),
            rgba(241, 241, 241, 1)
          );
        }

        &.shadow-right {
          right: 0;
          background: linear-gradient(
            to right,
            hsla(0, 0%, 100%, 0),
            rgba(241, 241, 241, 1)
          );
        }

        &.swiper-button-disabled {
          opacity: 0;
          z-index: 0;
        }
      }

      .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        width: auto;
        @media only screen and (max-width: 1024px) {
          display: none;
        }

        &.prev {
          left: 0;
        }

        &.next {
          right: 0;
        }

        &.hide {
          display: none;
        }
      }

      .swiper-container {
        @media only screen and (max-width: 1024px) {
          padding: 20px;
          margin: -20px;
        }
        .swiper-wrapper {
          height: fit-content !important;
        }
      }
    }
  }
}
</style>

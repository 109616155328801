<template>
  <div class="content-main">
    <ModalLogin
      :redirect="$route.path"
      @registeredModal="modalLogin = $event"
    />
    <section class="survey">
      <div class="container">
        <TitleSection :title="'Informasi Tambahan | ' + response.data.title" />
        <p class="desc">
          Lengkapi formulir di bawah ini untuk melanjutkan pendaftaran event
        </p>
        <div id="form"></div>
      </div>
    </section>
  </div>
</template>

<script>
import config from "../../../../config";
import ModalLogin from "/components/modal/Login";
import { createWidget } from "@typeform/embed";
import "@typeform/embed/build/css/widget.css";
import TitleSection from "@/components/TitleSection";

export default {
  async asyncData({ app, params, $axios, store, route, error }) {
    try {
      const response = await app.$axios.$get(
        `${config.EVENT_SERVICE_API}events/detail/${params.slug}`
      );

      let tes = [];
      for (const x in response.data.categories) {
        tes.push(response.data.categories[x].id);
      }
      let x = JSON.stringify(tes);
      const categoriesRecommend = x.substr(1, x.length - 2);

      store.commit("seo/SET_SEO", {
        title: response.data.title,
        desc: response.data.description
          ? response.data.description
          : response.data.title,
        image:
          response.data.thumbnail && response.data.thumbnail.large
            ? response.data.thumbnail.large
            : "dummy.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
      return { response, categoriesRecommend };
    } catch (e) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: { ModalLogin, TitleSection },
  data() {
    return {};
  },
  head() {
    return this.headReturn;
  },
  computed: {},
  methods: {
    async checkout() {
      let rawTicket =
        this.response.data.tickets.length > 0
          ? this.response.data.tickets[0]
          : JSON.stringify({});
      let rawData = this.response.data;
      delete rawData.tickets;
      // delete rawData.categories;

      let payload = {
        userId: this.$store.state.auth.user.id,
        email: this.$store.state.auth.user.email,
        fullname: this.$store.state.auth.user.fullname,
        eventId: this.response.data.id,
        ticketRaw: rawTicket,
        eventRaw: rawData,
      };

      await this.$axios
        .post(`${config.EVENT_SERVICE_API}events/attend`, payload)
        .then((res) => {
          if (res.data.message == "Ticket sudah habis.") {
            this.$toast.error(
              "Maaf, pendaftaran gagal karena tiket telah habis"
            );
          } else if (res.data.message == "events not found") {
            this.$toast.error("Event tidak ditemukan");
          } else {
            this.$toast.success(`Sukses, ${res.data.message}`);
            window.location.href = this.$router.resolve(
              `/event/${this.response.data.organizerSlug}/${this.response.data.slug}/checkout`
            ).href;
          }
        })
        .catch((e) => {
          this.$toast.error("Opps, Kesalahan sistem");
        });
    },
  },
  mounted() {
    if (this.$store.state.auth.loggedIn) {
      createWidget(this.response.data.typeform, {
        container: document.querySelector("#form"),
        onSubmit: (e) => {
          this.checkout();
        },
        hidden: {
          full_name: this.$store.state.auth.user.email,
          user_id: this.$store.state.auth.user.id,
          event_id: this.response.data.id,
        },
      });
    } else {
      if (process.env.browser) {
        this.modalLogin.show();
      }
    }
    this.url = window.location.href;
  },
};
</script>

<style lang="scss" scoped>
.content-main {
  .survey {
    padding: 32px 0 48px;
    .container {
      .title {
        margin-bottom: 8px;
      }
      .desc {
        font-size: 16px;
        color: #616161;
        padding-left: 29px;
        margin-bottom: 24px;
        @media only screen and (max-width: 1024px) {
          padding-left: 14px;
          font-size: 12px;
        }
      }
      #form {
        height: 35vw;
        @media only screen and (max-width: 1024px) {
          height: 130vw;
        }
      }
    }
  }
}
</style>

<template>

</template>

<script>
export default {
  asyncData({redirect}) {
    redirect(301, '/event');
  }
}
</script>

<template>
  <div class="content-main">
    <Shimmer v-if="carousel && carousel.loading" width="100%" height="343px" />
    <section id="banner" v-if="carousel.items.length > 0">
      <TopCarousel :data="carousel.items" width="1440" height="350" />
    </section>
    <!-- <section id="categories" class="event-category">
      <div
        class="container"
        v-if="!interest.loading && interest.items.length > 0"
      >
        <div class="title-content">
          <TitleSection title="KATEGORI EVENT TERPOPULER" />
        </div>
        <div
          class="py-2"
          v-swiper:mySwiperCategories="swiperOptions.categories"
          @transition-start="onSwiperSlideChange('categories')"
        >
          <div class="swiper-wrapper">
            <ShimmerCategory v-if="interest.loading" />
            <Link
              class="swiper-slide category"
              v-for="(category, index) in interest.items"
              :key="index"
              :to="{
                name: 'event-category',
                query: {
                  type: category.slug,
                },
              }"
              >{{ category.title }}
            </Link>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </section> -->
    <section
      id="event-narasi"
      :class="{
        'm-0': eventsNarasi.items.length == 0 && !eventsNarasi.loading,
      }"
    >
      <!-- START ADS -->
      <EventTopAds />
      <!-- END ADS -->
      <div class="container">
        <div class="title-content" v-if="eventsNarasi.items.length > 0">
          <TitleSection title="EVENT NARASI" />
          <Link
            :to="{
              name: 'event-category',
              query: {
                type: 'semua-topik',
              },
            }"
            class="see-all for-mobile"
            >Lihat Semua
          </Link>
        </div>
        <ShimmerListCard v-if="eventsNarasi.loading" />
        <div class="card-wrapper">
          <CardEleven
            v-show="!eventsNarasi.loading"
            v-for="(card, index) in eventsNarasi.items"
            :key="'card-event-narasi' + index"
            :image="
              card.thumbnail && card.thumbnail.large
                ? card.thumbnail.large
                : 'https://narasi.tv/storage/images/dummy.png'
            "
            :imageFromUrl="true"
            :ticket="card.ticketType"
            :categories="card.categories"
            :location="card.location"
            :title="card.title"
            :slug="card.slug"
            :description="card.description"
            :datetime="card.startDate"
            :price="card.price"
            :discount="card.discount"
            :to="{
              name: 'event-organizer-slug',
              params: {
                organizer: card.organizerSlug,
                slug: card.slug,
              },
            }"
            :startEvent="card.startDate"
            :endEvent="card.endDate"
            :startRegis="card.startRegistrationDate"
            :endRegis="card.endRegistrationDate"
          />
        </div>
        <div class="btn-wrapper" v-if="eventsNarasi.items.length > 0">
          <Link
            :to="{
              name: 'event-category',
              query: {
                type: 'semua-topik',
              },
            }"
            >Lihat Event Lainnya
          </Link>
          <Icons name="arrow-forward" />
        </div>
      </div>
    </section>
    <section
      id="linimasa-event-narasi"
      :class="eventsNarasi.items.length > 0 ? '' : 'remove-margin'"
    >
      <div class="container">
        <div class="title-content">
          <TitleSection title="LINIMASA EVENT NARASI" />
          <Link
            :to="{
              name: 'event-linimasa-event-narasi',
              query: {
                tahun: year,
              },
            }"
            class="see-all"
            >Lihat Semua
          </Link>
        </div>
        <div class="years">
          <div
            v-swiper:mySwiperYears="swiperOptions.years"
            @transition-start="onSwiperSlideChange('years')"
          >
            <div class="swiper-wrapper">
              <div
                v-for="(year, index) in listYears"
                :key="'year-' + index"
                class="swiper-slide"
              >
                {{ year }}
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
          <Icons
            name="arrow-left"
            color="#ffee39"
            class="arrow prev prev-years"
          />
          <Icons
            name="arrow-right"
            color="#ffee39"
            class="arrow next next-years"
          />
        </div>
      </div>
      <div class="container-fluid">
        <div class="position-relative">
          <div
            v-swiper:mySwiperLinimasa="swiperOptions.linimasa"
            @transition-start="onSwiperSlideChange('linimasa')"
          >
            <div
              class="swiper-wrapper"
              :class="{ 'justify-content-center': linimasa.loadingTwo }"
            >
              <ShimmerListCard v-if="linimasa.loading" />
              <ShimmerListCard v-if="linimasa.loading" />
              <div v-if="linimasa.loadingTwo" class="dot-spin-wrapper">
                <div class="dot-spin m-auto"></div>
              </div>
              <CardTwelve
                v-show="!linimasa.loading"
                class="swiper-slide"
                v-for="(card, index) in linimasa.items"
                :key="index"
                :image="
                  card.thumbnail && card.thumbnail.large
                    ? card.thumbnail.large
                    : 'https://narasi.tv/storage/images/dummy.png'
                "
                :imageFromUrl="true"
                :organizer="card.organizer"
                :categories="card.categories"
                :location="card.location"
                :datetime="card.startDate"
                :title="card.title"
                :slug="card.slug"
                :description="card.description"
                :createdAt="card.createdAt"
                :to="{
                  name: 'event-linimasa-event-narasi-slug',
                  params: {
                    slug: card.slug,
                  },
                }"
              />
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import TopCarousel from "/components/event/TopCarousel";
import CardEleven from "/components/cards/CardEleven";
import CardTwelve from "/components/cards/CardTwelve";
import EventTopAds from "/components/ads/event/EventTopAds.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import config from "../../config";
import Shimmer from "@/components/Shimmer";
import TitleSection from "@/components/TitleSection";
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import ShimmerListCard from "@/components/shimmer/ListCard";

export default {
  async asyncData({ error, store, route, redirect }) {
    try {
      store.commit("seo/SET_SEO", {
        title: "Event",
        desc: "Event variatif yang dipersembahkan oleh Narasi.tv",
        image: "https://narasi.tv/icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: {
    Icons,
    ShimmerListCard,
    Link,
    TitleSection,
    Shimmer,
    Swiper,
    SwiperSlide,
    TopCarousel,
    CardEleven,
    CardTwelve,
    EventTopAds,
  },
  data() {
    return {
      listYears: [],
      totalSlide: null,
      year: new Date().getFullYear(),
      arrow: {
        years: {
          prev: true,
          next: false,
        },
      },
      swiperOptions: {
        categories: {
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          mousewheel: {
            releaseOnEdges: true,
          },
          breakpoints: {
            1023: {
              slidesPerView: "auto",
              spaceBetween: 8,
              enabled: true,
            },
            320: {
              slidesPerView: 2,
              spaceBetween: 8,
              enabled: false,
              allowSlideNext: false,
              allowSlidePrev: false,
            },
          },
        },
        linimasa: {
          pagination: false,
          speed: 1000,
          mousewheel: {
            releaseOnEdges: true,
          },
          breakpoints: {
            1023: {
              slidesPerView: 3.9,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
          },
        },
        years: {
          // pagination: false,
          speed: 1000,
          effect: "fade",
          initialSlide: 4,
          fadeEffect: {
            crossFade: true,
          },
          navigation: {
            prevEl: ".prev-years",
            nextEl: ".next-years",
          },
          breakpoints: {
            1023: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 40,
            },
          },
        },
      },
    };
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      interest: (state) => {
        return state.events.eventCategories;
      },
      carousel: (state) => {
        return state.events.eventCarousel;
      },
      eventsNarasi: (state) => {
        return state.events.eventsNarasi;
      },
      linimasa: (state) => {
        return state.events.dataLinimasa;
      },
    }),
  },
  watch: {
    year(newValue, oldValue) {
      this.linimasaData();
    },
  },
  methods: {
    async initData() {
      await this.$store.dispatch("events/carousel");
      await this.$store.dispatch("events/dataEventsNarasi");
      await this.$store.dispatch("events/setCategories");
    },
    async linimasaData() {
      await this.$store.dispatch("events/dataLinimasaNarasi", this.year);
    },
    onSwiperSlideChange(params) {
      switch (params) {
        case "years":
          this.arrow.years.next = this.mySwiperYears.isEnd;
          this.arrow.years.prev = this.mySwiperYears.isBeginning;
          this.year = this.listYears[this.mySwiperYears.activeIndex];
          break;
      }
    },
  },
  mounted() {
    let years = this.$moment().diff("2018-01-01", "years");
    for (let i = 0; i < years; i++) {
      this.listYears.push(2019 + i);
    }
    this.linimasaData();
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.dot-spin-wrapper {
  height: 40px;
  margin: 150px auto;
}
.dot-spin {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
    18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    0 18px 0 0 rgba(152, 128, 255, 0),
    -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    -18px 0 0 0 rgba(152, 128, 255, 0),
    -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
  animation: dotSpin 1.5s infinite linear;
}

@keyframes dotSpin {
  0%,
  100% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
      18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff,
      12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #9880ff,
      12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 0 #9880ff,
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 #9880ff,
      0 18px 0 0 #9880ff, -12.72984px 12.72984px 0 0 #9880ff,
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #9880ff,
      -12.72984px 12.72984px 0 0 #9880ff, -18px 0 0 0 #9880ff,
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 #9880ff,
      -18px 0 0 0 #9880ff, -12.72984px -12.72984px 0 0 #9880ff;
  }
  75% {
    box-shadow: 0 -18px 0 0 #9880ff,
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #9880ff,
      -12.72984px -12.72984px 0 0 #9880ff;
  }
  87.5% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 #9880ff;
  }
}
.swiper-wrapper {
  height: fit-content !important;
}
.content-main {
  .title-content {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 16px;
    }

    .title {
      color: #4a25aa;
      font-size: 20px;
      font-weight: 700;
      padding-left: 30px;
      border-left: 10px solid #ffe900;
      margin: 0;
      @media only screen and (max-width: 1024px) {
        font-size: 16px;
        padding-left: 10px;
        border-width: 5px;
      }
    }

    .see-all {
      font-size: 16px;
      font-weight: bold;
      text-decoration: underline !important;
      @media only screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }
  }

  .container {
    .shadow-arrow {
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100px;
      height: 100%;
      padding: 0;
      background: rgb(0, 0, 0);
      z-index: 2;
      opacity: 1;
      transition: ease-in-out 0.5s;
      @media only screen and (max-width: 1024px) {
        display: none;
      }

      &.shadow-left {
        left: 0;
        background: linear-gradient(
          to left,
          hsla(0, 0%, 100%, 0),
          rgba(241, 241, 241, 1)
        );
      }

      &.shadow-right {
        right: 0;
        background: linear-gradient(
          to right,
          hsla(0, 0%, 100%, 0),
          rgba(241, 241, 241, 1)
        );
      }

      &.hide {
        opacity: 0;
        z-index: 0;
      }
    }

    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      width: auto;
      @media only screen and (max-width: 1024px) {
        display: none;
      }

      &.next-years,
      &.prev-years {
        @media only screen and (max-width: 1024px) {
          display: block;
        }
      }

      &.prev {
        left: 0;
      }

      &.next {
        right: 0;
      }

      &.hide {
        display: none;
      }
    }
  }

  // #categories {
  //   margin-top: 32px;
  //   @media only screen and (max-width: 1024px) {
  //     margin-top: 24px;
  //   }

  //   .swiper-container {
  //     padding: 10px 0;

  //     .swiper-wrapper {
  //       height: fit-content !important;
  //       @media only screen and (max-width: 1024px) {
  //         flex-wrap: wrap;
  //         grid-row-gap: 12px;
  //       }

  //       .swiper-slide {
  //         &.category {
  //           color: #616161;
  //           background: #fafafa;
  //           width: fit-content !important;
  //           border-radius: 50px;
  //           white-space: nowrap;
  //           font-size: 16px;
  //           padding: 10px 30px;
  //           border: 1px solid #e0e0e0;
  //           @media only screen and (max-width: 1024px) {
  //             font-size: 12px;
  //             padding: 5px 15px;
  //           }

  //           &:hover {
  //             @media only screen and (min-width: 1024px) {
  //               background: #8166c8;
  //               color: #fafafa !important;
  //             }
  //           }

  //           &:active {
  //             @media only screen and (min-width: 1024px) {
  //               background: #6242b4;
  //               color: #e0e0e0 !important;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  #event-narasi {
    padding: 48px 0;
    @media only screen and (max-width: 1024px) {
      padding: 32px 0;
    }

    .for-desktop {
      display: block;
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
    .for-mobile {
      display: none;
      @media only screen and (max-width: 1024px) {
        display: block;
      }
    }

    .container {
      .card-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        @media only screen and (max-width: 1024px) {
          grid-template-columns: repeat(1, 1fr);
        }
        .card-eleven {
          width: 100% !important;
        }
      }
      .btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        a {
          font-size: 20px;
          font-weight: 700;
          color: #4a25aa;
          text-decoration: underline !important;
          @media only screen and (max-width: 1024px) {
            font-size: 18px;
          }
          &:hover {
            color: #4a25aa !important;
          }
        }
        svg {
          width: 16px;
          height: auto;
          margin-left: 10px;
          @media only screen and (max-width: 1024px) {
            width: 14px;
          }
        }
      }
    }
  }

  #linimasa-event-narasi {
    background: linear-gradient(270deg, #4a25aa 0%, #b60f7f 100%);
    margin-top: 48px;
    padding: 32px 0 64px;
    @media only screen and (max-width: 1024px) {
      margin-top: 32px;
      padding: 32px 0;
    }
    &.remove-margin {
      margin: 0;
    }
    .container-fluid {
      padding: 0;
      @media only screen and (max-width: 1024px) {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
      }

      .swiper-container {
        @media only screen and (max-width: 1024px) {
          padding: 20px;
          margin: -20px;
        }
      }
    }

    .title-content {
      .title {
        color: #ffe900;
        font-weight: bold;
        padding-left: 30px;
        border-left: 10px solid #ffe900;
        @media only screen and (max-width: 1024px) {
          font-size: 16px;
          padding-left: 10px;
          border-width: 5px;
        }
      }

      .see-all {
        font-weight: 500;
        text-decoration: underline !important;
        color: #ffe900;
        @media only screen and (max-width: 1024px) {
          font-size: 12px;
        }

        &:hover {
          color: #ffe900 !important;
        }
      }
    }

    .years {
      position: relative;
      width: 16%;
      margin-bottom: 30px;
      @media only screen and (max-width: 1024px) {
        width: 130px;
      }

      .swiper-slide {
        font-size: 24px;
        font-weight: 700;
        color: #ffee39;
        text-align: center;
        @media only screen and (max-width: 1024px) {
          font-size: 18px;
        }
      }

      svg {
        border: 1px solid #ffee39;
        border-radius: 5px;
        width: 50px;
        height: 50px;
        @media only screen and (max-width: 1024px) {
          width: 30px;
          height: 30px;
        }

        path {
          fill: #ffee39;
        }
      }
    }
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-main"},[(_vm.carousel && _vm.carousel.loading)?_c('Shimmer',{attrs:{"width":"100%","height":"343px"}}):_vm._e(),_vm._v(" "),(_vm.carousel.items.length > 0)?_c('section',{attrs:{"id":"banner"}},[_c('TopCarousel',{attrs:{"data":_vm.carousel.items,"width":"1440","height":"350"}})],1):_vm._e(),_vm._v(" "),_c('section',{class:{
      'm-0': _vm.eventsNarasi.items.length == 0 && !_vm.eventsNarasi.loading,
    },attrs:{"id":"event-narasi"}},[_c('EventTopAds'),_vm._v(" "),_c('div',{staticClass:"container"},[(_vm.eventsNarasi.items.length > 0)?_c('div',{staticClass:"title-content"},[_c('TitleSection',{attrs:{"title":"EVENT NARASI"}}),_vm._v(" "),_c('Link',{staticClass:"see-all for-mobile",attrs:{"to":{
            name: 'event-category',
            query: {
              type: 'semua-topik',
            },
          }}},[_vm._v("Lihat Semua\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.eventsNarasi.loading)?_c('ShimmerListCard'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-wrapper"},_vm._l((_vm.eventsNarasi.items),function(card,index){return _c('CardEleven',{directives:[{name:"show",rawName:"v-show",value:(!_vm.eventsNarasi.loading),expression:"!eventsNarasi.loading"}],key:'card-event-narasi' + index,attrs:{"image":card.thumbnail && card.thumbnail.large
              ? card.thumbnail.large
              : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"ticket":card.ticketType,"categories":card.categories,"location":card.location,"title":card.title,"slug":card.slug,"description":card.description,"datetime":card.startDate,"price":card.price,"discount":card.discount,"to":{
            name: 'event-organizer-slug',
            params: {
              organizer: card.organizerSlug,
              slug: card.slug,
            },
          },"startEvent":card.startDate,"endEvent":card.endDate,"startRegis":card.startRegistrationDate,"endRegis":card.endRegistrationDate}})}),1),_vm._v(" "),(_vm.eventsNarasi.items.length > 0)?_c('div',{staticClass:"btn-wrapper"},[_c('Link',{attrs:{"to":{
            name: 'event-category',
            query: {
              type: 'semua-topik',
            },
          }}},[_vm._v("Lihat Event Lainnya\n        ")]),_vm._v(" "),_c('Icons',{attrs:{"name":"arrow-forward"}})],1):_vm._e()],1)],1),_vm._v(" "),_c('section',{class:_vm.eventsNarasi.items.length > 0 ? '' : 'remove-margin',attrs:{"id":"linimasa-event-narasi"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title-content"},[_c('TitleSection',{attrs:{"title":"LINIMASA EVENT NARASI"}}),_vm._v(" "),_c('Link',{staticClass:"see-all",attrs:{"to":{
            name: 'event-linimasa-event-narasi',
            query: {
              tahun: _vm.year,
            },
          }}},[_vm._v("Lihat Semua\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"years"},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiperYears",value:(_vm.swiperOptions.years),expression:"swiperOptions.years",arg:"mySwiperYears"}],on:{"transition-start":function($event){return _vm.onSwiperSlideChange('years')}}},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.listYears),function(year,index){return _c('div',{key:'year-' + index,staticClass:"swiper-slide"},[_vm._v("\n              "+_vm._s(year)+"\n            ")])}),0),_vm._v(" "),_c('div',{staticClass:"swiper-pagination"})]),_vm._v(" "),_c('Icons',{staticClass:"arrow prev prev-years",attrs:{"name":"arrow-left","color":"#ffee39"}}),_vm._v(" "),_c('Icons',{staticClass:"arrow next next-years",attrs:{"name":"arrow-right","color":"#ffee39"}})],1)]),_vm._v(" "),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"position-relative"},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiperLinimasa",value:(_vm.swiperOptions.linimasa),expression:"swiperOptions.linimasa",arg:"mySwiperLinimasa"}],on:{"transition-start":function($event){return _vm.onSwiperSlideChange('linimasa')}}},[_c('div',{staticClass:"swiper-wrapper",class:{ 'justify-content-center': _vm.linimasa.loadingTwo }},[(_vm.linimasa.loading)?_c('ShimmerListCard'):_vm._e(),_vm._v(" "),(_vm.linimasa.loading)?_c('ShimmerListCard'):_vm._e(),_vm._v(" "),(_vm.linimasa.loadingTwo)?_c('div',{staticClass:"dot-spin-wrapper"},[_c('div',{staticClass:"dot-spin m-auto"})]):_vm._e(),_vm._v(" "),_vm._l((_vm.linimasa.items),function(card,index){return _c('CardTwelve',{directives:[{name:"show",rawName:"v-show",value:(!_vm.linimasa.loading),expression:"!linimasa.loading"}],key:index,staticClass:"swiper-slide",attrs:{"image":card.thumbnail && card.thumbnail.large
                  ? card.thumbnail.large
                  : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"organizer":card.organizer,"categories":card.categories,"location":card.location,"datetime":card.startDate,"title":card.title,"slug":card.slug,"description":card.description,"createdAt":card.createdAt,"to":{
                name: 'event-linimasa-event-narasi-slug',
                params: {
                  slug: card.slug,
                },
              }}})})],2),_vm._v(" "),_c('div',{staticClass:"swiper-pagination"})])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
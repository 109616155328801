<template>
  <div class="untukmu-page">
    <section id="breadcrumbs">
      <div class="container">
        <div class="breadcrumbs">
          <Link :to="'/'">HOME <Icons name="arrow-right" /></Link>
          <Link
            :to="{
              name: 'indeks',
            }"
            >INDEKS <Icons name="arrow-right"
          /></Link>
          <Link
            :to="{
              name: $route.name,
              query: $route.query,
            }"
            class="active"
            >{{ title_interest ? title_interest : "Semua" }}
            <Icons name="arrow-right"
          /></Link>
        </div>
      </div>
    </section>
    <section class="class-category">
      <div class="container">
        <div
          class="py-2"
          v-swiper:mySwiperClassCategory="swiperOptionsClassCategory"
        >
          <div class="swiper-wrapper">
            <ShimmerCategory v-if="interests.loading" />
            <Link
              :to="{
                name: 'indeks',
              }"
              class="swiper-slide category"
              :class="{ 'Link-exact-active': !$route.query.type }"
              >Semua
            </Link>
            <Link
              v-for="(item, index) in interests.items.slice(1)"
              :key="item.id"
              :to="{
                name: 'indeks',
                query: {
                  type: item.slug,
                },
              }"
              :class="item.slug == $route.query.type ? 'Link-exact-active' : ''"
              class="swiper-slide category"
              @click.native="setTitleInterest(index)"
              >{{ item.title }}
            </Link>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </section>
    <section id="content-wrapper">
      <div class="container">
        <div class="title-wrapper" v-if="title_interest">
          <h2
            class="title"
            v-if="interests.items && interests.items.length > 0"
          >
            {{ title_interest }}
          </h2>
          <p class="desc" v-if="desc_interest">
            {{ desc_interest }}
          </p>
        </div>
        <div class="list-card-category">
          <ShimmerCardHomeLandscape
            v-show="interest_article.loading"
            v-for="(item, index) in 5"
            :key="index"
          />
          <CardFive
            v-show="!interest_article.loading"
            v-for="item in interest_article.items"
            :key="item.id"
            :image="
              item.thumbnail && item.thumbnail.large
                ? item.thumbnail.large
                : 'https://narasi.tv/storage/images/dummy.png'
            "
            :imageFromUrl="true"
            :duration="item.timeVideo ? item.timeVideo : '06:00'"
            :channel="item.channel.title"
            :channelSlug="item.channel.slug"
            :programSlug="item.channel.program ? item.channel.program.slug : ''"
            :category="item.category ? item.category.title : ''"
            :categorySlug="item.category ? item.category.slug : ''"
            :title="item.title"
            :isText="item.isText"
            :isAdvertorial="item.isAdvertorial"
            :isThumbnailSquare="true"
            :to="
              item.isText
                ? {
                    name: `read-channel-slug`,
                    params: {
                      channel: item.channel.slug,
                      slug: item.slug,
                    },
                    query: {
                      ref: 'indeks',
                    },
                  }
                : {
                    name: 'video-channel-slug',
                    params: {
                      channel: item.channel.slug,
                      slug: item.slug,
                    },
                    query: {
                      ref: 'indeks',
                    },
                  }
            "
          />
        </div>
        <div
          class="pagination-cards"
          v-if="
            interest_article.items.length > 0 &&
            interest_article.pagination.pages > 1
          "
        >
          <paginate
            v-if="
              interest_article.pagination &&
              interest_article.pagination.pages &&
              interest_article.pagination.pages > 1
            "
            :page-count="interest_article.pagination.pages"
            :page-range="3"
            :margin-pages="1"
            prev-class="arrow"
            next-class="arrow"
            :click-handler="clickCallback"
            :container-class="'pagination'"
            :page-class="'page-item'"
            v-model="activePaginate"
          >
            <template slot="prevContent">
              <Icons name="arrow-left" color="#757575" />
            </template>
            <template slot="nextContent">
              <Icons name="arrow-right" color="#757575" />
            </template>
          </paginate>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Vue from "vue";
import config from "../../config";
import CardTwo from "/components/cards/CardTwo.vue";
import CardFive from "/components/cards/CardFive.vue";
import Paginate from "/node_modules/vuejs-paginate/src/components/Paginate";
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import ShimmerCategory from "@/components/shimmer/Category";
import ShimmerCardHomeLandscape from "@/components/shimmer/CardHomeLandscape";

Vue.component("paginate", Paginate);

export default {
  watchQuery: true,
  async asyncData({ app, params, query, store, route, error }) {
    try {
      const response = await app.$axios.$get(
        `${config.CORE_SERVICE_API}articles/interests/?sort=publishDate&dir=DESC&limit=1000`
      );
      var title_interest = "";
      var desc_interest = "";
      for (const x in response.data) {
        if (query.type) {
          if (response.data[x].slug === query.type) {
            title_interest = response.data[x].title;
            desc_interest = response.data[x].description;
          }
        }
      }
      store.commit("seo/SET_SEO", {
        title: title_interest ? title_interest : "Semua",
        desc: "Kumpulan artikel berdasarkan kategori " + title_interest,
        image: "https://narasi.tv/new-icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
      if (query.type && !title_interest) {
        return (
          error({ statusCode: 404, message: "Page not found" }), title_interest
        );
      }
      return { title_interest, desc_interest };
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: {
    Icons,
    ShimmerCardHomeLandscape,
    ShimmerCategory,
    Link,
    CardTwo,
    CardFive,
  },
  data() {
    return {
      activePaginate: this.$route.query.page ? this.$route.query.page : 1,
      page: 1,
      arrow: {
        dummy: {
          prev: true,
          next: false,
        },
      },
      swiperOptions: {
        dummy: {
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          navigation: {
            prevEl: ".prev-dummy",
            nextEl: ".next-dummy",
          },
          breakpoints: {
            1023: {
              slidesPerView: 4,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1.2,
              spaceBetween: 8,
            },
          },
        },
      },
      swiperOptionsClassCategory: {
        // freeMode: true,
        pagination: false,
        autoHeight: true,
        calculateHeight: true,
        mousewheel: {
          releaseOnEdges: true,
        },
        breakpoints: {
          1023: {
            slidesPerView: "auto",
            spaceBetween: 10,
            enabled: true,
          },
          320: {
            slidesPerView: "auto",
            spaceBetween: 10,
          },
        },
      },
    };
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      interests: (state) => {
        return state.articles.interests;
      },
      interest_article: (state) => {
        return state.articles.interest_article;
      },
    }),
  },
  watch: {
    "$route.query.page": {
      handler: function (page) {
        if (page) {
          let page_ = parseInt(page);
          this.page = page_;
        }
      },
      deep: true,
      immediate: true,
    },
    "$route.query.type": {
      handler: function (interest) {
        this.$store.dispatch("articles/getArticleByInterest", {
          type: interest ? interest : "",
          page: this.page,
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async initData() {
      await this.$store.dispatch("articles/getInterests");
      await this.$store.dispatch("articles/getArticleByInterest", {
        type: this.$route.query.type ? this.$route.query.type : "",
        page: this.page,
      });
      // await this.$store.dispatch("home/getUntukmu");
    },
    onSwiperSlideChange(params) {
      // this.totalSlide = swiper.slides.length;
      switch (params) {
        case "dummy":
          this.arrow.dummy.next = this.mySwiperdummy.isEnd;
          this.arrow.dummy.prev = this.mySwiperdummy.isBeginning;
          break;
      }
    },
    setTitleInterest(i) {
      this.indexInterest = i + 1;
    },
    clickCallback(page) {
      this.page = page;

      this.$router.replace({
        name: "indeks",
        query: this.$route.query.type
          ? {
              type: this.$route.query.type,
              page: page,
            }
          : { page: page },
      });

      this.$store.dispatch("articles/getArticleByInterest", {
        type: this.$route.query.type ? this.$route.query.type : "",
        page: page,
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.untukmu-page {
  padding: 32px 0 48px;
  #breadcrumbs {
    margin-bottom: 24px;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 32px;
    }
    .container {
      .breadcrumbs {
        display: flex;
        align-items: center;
        a {
          font-size: 14px;
          color: #616161;
          display: flex;
          align-items: center;
          svg {
            width: 18px;
            height: auto;
            margin: 0 10px;
          }
          &.active {
            color: #34128c;
            font-weight: 700;
          }
          &:hover {
            color: #616161 !important;
          }
          &:last-child {
            svg {
              display: none;
            }
          }
        }
      }
    }
  }
  .class-category {
    margin-bottom: 32px;
    @media only screen and (max-width: 1024px) {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .swiper-container {
      @media only screen and (max-width: 1024px) {
        padding: 20px;
        margin: -20px;
      }

      .swiper-wrapper {
        height: fit-content !important;
        @media only screen and (max-width: 1024px) {
          flex-wrap: nowrap;
          // flex-wrap: wrap;
        }

        .swiper-slide {
          &.category {
            color: #616161;
            background: white;
            width: fit-content !important;
            border-radius: 50px;
            white-space: nowrap;
            font-size: 16px;
            padding: 10px 30px;
            border: 1px solid #e0e0e0;
            @media only screen and (max-width: 1024px) {
              font-size: 14px;
              padding: 5px 20px;
              margin: 0 5px 5px 0;
            }

            &:hover {
              background: #6242b4;
              color: #fafafa !important;
            }

            &.Link-exact-active {
              background: #4a25aa;
              color: #ffe900 !important;
            }
          }
        }
      }
    }
  }

  #content-wrapper {
    margin-top: 48px;
    @media only screen and (max-width: 1024px) {
      margin-top: 40px;
    }

    .container {
      .title-wrapper {
        margin-bottom: 48px;
        @media only screen and (max-width: 1024px) {
          margin-bottom: 24px;
        }

        .title {
          font-size: 40px;
          font-weight: 700;
          color: #4a25aa;
          margin-bottom: 16px;
          @media only screen and (max-width: 1024px) {
            font-size: 24px;
            margin-bottom: 8px;
          }
        }

        .desc {
          font-size: 20px;
          font-weight: 400;
          color: #757575;
          margin: 0;
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
        }
      }

      .list-card-category {
        position: relative;
        margin-bottom: 48px;
        @media only screen and (max-width: 1024px) {
          margin-bottom: 24px;
        }

        .shadow-arrow {
          pointer-events: none;
          position: absolute;
          top: 0;
          width: 100px;
          height: 100%;
          padding: 0;
          background: rgb(0, 0, 0);
          z-index: 2;
          opacity: 1;
          transition: ease-in-out 0.5s;
          @media only screen and (max-width: 1024px) {
            display: none;
          }

          &.shadow-left {
            left: 0;
            background: linear-gradient(
              to left,
              hsla(0, 0%, 100%, 0),
              rgba(241, 241, 241, 1)
            );
          }

          &.shadow-right {
            right: 0;
            background: linear-gradient(
              to right,
              hsla(0, 0%, 100%, 0),
              rgba(241, 241, 241, 1)
            );
          }

          &.hide {
            opacity: 0;
            z-index: 0;
          }
        }

        .arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
          width: auto;
          @media only screen and (max-width: 1024px) {
            display: none;
          }

          &.prev {
            left: 0;
          }

          &.next {
            right: 0;
          }

          &.hide {
            display: none;
          }
        }

        .swiper-container {
          @media only screen and (max-width: 1024px) {
            padding: 20px !important;
            margin: -20px !important;
          }
        }
      }

      .pagination-cards {
        padding: 24px 0;
        @media only screen and (max-width: 1024px) {
          padding: 32px 0 0;
        }

        .pagination {
          justify-content: flex-end;
          margin: 0;
          align-items: center;
          @media only screen and (max-width: 1024px) {
            justify-content: center;
          }

          ::v-deep .arrow {
            display: flex;
            align-items: center;
          }

          ::v-deep .arrow-pagination {
            svg {
              width: 25px;
              height: 25px;
            }
          }

          ::v-deep .page-item {
            a {
              font-size: 12px;
              font-weight: 400;
              color: #757575;
              background: #e0e0e0;
              width: 25px;
              height: 25px;
              margin: 0 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              cursor: pointer;
            }

            &.active {
              a {
                background: #4a25aa;
                color: #fafafa !important;
              }
            }

            &:hover {
              a {
                background: #4a25aa;
                color: #9e9e9e !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>

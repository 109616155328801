var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-main"},[_c('section',{attrs:{"id":"breadcrumbs"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"breadcrumbs"},[_c('Link',{attrs:{"to":'/'}},[_vm._v("HOME "),_c('Icons',{attrs:{"name":"arrow-right"}})],1),_vm._v(" "),_c('Link',{attrs:{"to":{
            name: 'event',
          }}},[_vm._v("EVENT "),_c('Icons',{attrs:{"name":"arrow-right"}})],1),_vm._v(" "),_c('Link',{staticClass:"active",attrs:{"to":{
            name: _vm.$route.name,
            query: _vm.$route.query,
          }}},[_vm._v(_vm._s(_vm.title_interest)+" "),_c('Icons',{attrs:{"name":"arrow-right"}})],1)],1)])]),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"title-wrapper"},[(_vm.eventDataByCategory.loading)?_c('Shimmer',{staticClass:"rounded-pill mb-3",attrs:{"width":"50%","height":"30px"}}):_c('div',{staticClass:"title"},[_vm._v("Kategori Event Terpopuler")]),_vm._v(" "),(_vm.eventDataByCategory.loading)?_c('Shimmer',{staticClass:"rounded-pill",attrs:{"width":"100%","height":"30px"}}):_c('div',{staticClass:"desc"},[_vm._v("\n        Pilih minat Anda untuk mendapatkan saran acara berdasarkan apa yang\n        Anda sukai\n      ")])],1),_vm._v(" "),_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiperCategories",value:(_vm.swiperOptionsCategories),expression:"swiperOptionsCategories",arg:"mySwiperCategories"}],staticClass:"category-wrapper",on:{"transition-start":function($event){return _vm.onSwiperSlideChange('categories')}}},[_c('div',{staticClass:"swiper-wrapper"},[(_vm.eventDataByCategory.loading)?_c('ShimmerCategory'):_vm._e(),_vm._v(" "),_c('Link',{directives:[{name:"show",rawName:"v-show",value:(!_vm.eventDataByCategory.loading),expression:"!eventDataByCategory.loading"}],staticClass:"swiper-slide category",class:{
            'Link-exact-active': _vm.$route.query.type == 'semua-topik',
          },attrs:{"to":{
            name: 'event-category',
            query: {
              type: 'semua-topik',
            },
          }}},[_vm._v("Semua Topik\n        ")]),_vm._v(" "),_vm._l((_vm.dataInterest),function(item,index){return _c('Link',{directives:[{name:"show",rawName:"v-show",value:(!_vm.eventDataByCategory.loading),expression:"!eventDataByCategory.loading"}],key:index,staticClass:"swiper-slide category",class:{
            'Link-exact-active': _vm.$route.query.type == item.slug,
          },attrs:{"to":{
            name: 'event-category',
            query: {
              type: item.slug,
            },
          }}},[_vm._v(_vm._s(item.title)+"\n        ")])})],2)])]),_vm._v(" "),_c('transition-group',{staticClass:"container",attrs:{"name":"list","tag":"div"}},[(_vm.eventDataByCategory.loading)?_c('ShimmerCardSeeAll',{key:"shimmer"}):_vm._e(),_vm._v(" "),_vm._l((_vm.eventDataByCategory.items),function(card,index){return _c('CardThirteen',{key:'category-card-' + index,attrs:{"image":card.thumbnail && card.thumbnail.large
          ? card.thumbnail.large
          : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"organizer":card.organizerSlug,"categories":card.categories,"location":card.location,"title":card.title,"slug":card.slug,"description":card.description,"datetime":card.startDate,"price":card.price ? card.price : 0,"discount":card.discount ? card.discount : 100,"to":{
        name: 'event-organizer-slug',
        params: {
          organizer: card.organizerSlug,
          slug: card.slug,
        },
      }}})})],2),_vm._v(" "),(
      _vm.eventDataByCategory.items.length > 0 &&
      _vm.eventDataByCategory.pagination &&
      _vm.eventDataByCategory.pagination.pages > 1
    )?_c('div',{staticClass:"pagination-cards"},[(
        _vm.eventDataByCategory &&
        _vm.eventDataByCategory.pagination.pages &&
        _vm.eventDataByCategory.pagination.pages > 1
      )?_c('paginate',{attrs:{"page-count":_vm.eventDataByCategory.pagination.pages,"page-range":3,"margin-pages":1,"prev-class":"arrow","next-class":"arrow","click-handler":_vm.clickCallback,"container-class":'pagination',"page-class":'page-item'},model:{value:(_vm.activePaginate),callback:function ($$v) {_vm.activePaginate=$$v},expression:"activePaginate"}},[_c('template',{slot:"prevContent"},[_c('Icons',{attrs:{"name":"arrow-left","color":"#757575"}})],1),_vm._v(" "),_c('template',{slot:"nextContent"},[_c('Icons',{attrs:{"name":"arrow-right","color":"#757575"}})],1)],2):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="content-main">
    <Breadcrumbs background="#f1f1f1" />
    <div class="container">
      <div class="title-wrapper">
        <div class="title">LINIMASA EVENT NARASI</div>
        <div class="desc">
          Menampilkan konten-konten event Narasi yang telah berlalu berdasarkan
          tahun pelaksanaan event.
        </div>
      </div>
      <div class="years d-flex justify-content-center" v-swiper:mySwiperCategories="swiperOptions.years">
        <div class="swiper-wrapper">
          <Link v-for="(year, index) in listYears" :key="index" :to="{
            name: 'event-linimasa-event-narasi',
            query: {
              tahun: year,
            },
          }" class="year swiper-slide" :class="year === $route.query.tahun ? 'active' : ''">{{ year }}
          </Link>
        </div>
      </div>
      <div class="event-list">
        <ShimmerCardLinimasa v-if="linimasa.loading" />
        <CardTwelve class="event-item" v-for="(card, index) in linimasa.items" :key="index" :bgWhite="true" :image="card.thumbnail && card.thumbnail.large
          ? card.thumbnail.large
          : 'https://narasi.tv/storage/images/dummy.png'
          " :imageFromUrl="true" :organizer="card.organizer" :categories="card.categories" :location="card.location"
          :datetime="card.startDate" :title="card.title" :slug="card.slug" :description="card.description"
          :createdAt="card.createdAt" :to="{
            name: 'event-linimasa-event-narasi-slug',
            params: {
              slug: card.slug,
            },
          }" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../../config";
import CardTwelve from "/components/cards/CardTwelve";
import Breadcrumbs from "/components/Breadcrumbs";
import Link from "/components/Link";
import ShimmerCardLinimasa from "/components/shimmer/CardLinimasa";

export default {
  async asyncData({ app, params, store, route }) {
    try {
      store.commit("seo/SET_SEO", {
        title: "Event Linimasa",
        desc: "Narasi Event",
        image: "https://www.narasi.tv/icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      console.log(err);
    }
  },
  components: {
    ShimmerCardLinimasa,
    Link,
    Breadcrumbs,
    CardTwelve,
  },
  data() {
    return {
      listYears: [],
      year: this.$route.query.tahun,
      years: [
        {
          name: "2022",
        },
        {
          name: "2021",
        },
        {
          name: "2020",
        },
        {
          name: "2019",
        },
      ],
      swiperOptions: {
        years: {
          // freeMode: true,
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          // autoplay: {
          //   delay: 5000,
          // },
          mousewheel: {
            releaseOnEdges: true,
          },
          breakpoints: {
            1023: {
              slidesPerView: "auto",
              spaceBetween: 16,
              enabled: true,
            },
            320: {
              slidesPerView: "auto",
              spaceBetween: 10,
              enabled: false,
              // allowSlideNext: false,
              // allowSlidePrev: false,
            },
          },
        },
      },
    };
  },
  head() {
    return this.headReturn;
  },

  watch: {
    "$route.query.tahun": {
      handler: function (tahun) {
        this.year = tahun;
        this.initData();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      linimasa: (state) => {
        return state.events.dataLinimasa;
      },
    }),
  },
  methods: {
    async initData() {
      await this.$store.dispatch("events/dataLinimasaNarasi", this.year);
    },
  },
  mounted() {
    let years = this.$moment().diff("2018-01-01", "years");
    for (let i = 0; i < years; i++) {
      this.listYears.push(2019 + i);
    }
  },
};
</script>

<style lang="scss" scoped>
.content-main {
  .container {
    padding-bottom: 48px;

    .title-wrapper {
      text-align: center;
      margin-bottom: 32px;

      @media only screen and (max-width: 1024px) {
        margin-bottom: 24px;
      }

      .title {
        font-size: 40px;
        margin-bottom: 15px;
        color: #4a25aa;
        font-weight: 700;

        @media only screen and (max-width: 1024px) {
          font-size: 24px;
          margin-bottom: 8px;
        }
      }

      .desc {
        font-size: 20px;
        color: #0c0b0d;

        @media only screen and (max-width: 1024px) {
          font-size: 12px;
        }
      }
    }

    .event-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;
    }

    .years {
      &.swiper-container {
        margin-bottom: 32px;

        @media only screen and (max-width: 1024px) {
          padding: 0 20px !important;
          margin: 0 -20px 24px !important;
        }

        .swiper-wrapper {
          justify-content: center;

          @media only screen and (max-width: 1024px) {
            justify-content: flex-start;
          }

          .year {
            font-size: 16px;
            padding: 5px 30px;
            border: 1px solid #e0e0e0;
            color: #616161;
            background: #fafafa;
            width: fit-content;
            border-radius: 50px;

            @media only screen and (max-width: 1024px) {
              padding: 3px 20px;
            }

            &.active {
              background: #4a25aa;
              color: #ffe900 !important;
            }

            &.Link-exact-active {
              background: #4a25aa;
              color: #ffe900 !important;
            }

            &:hover {
              @media only screen and (min-width: 1024px) {
                background: #8166c8;
                color: #fafafa !important;
              }
            }

            &:active {
              @media only screen and (min-width: 1024px) {
                background: #6242b4;
                color: #e0e0e0 !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-main"},[_c('Breadcrumbs',{attrs:{"background":"#f1f1f1"}}),_vm._v(" "),(_vm.response.items.thumbnail && _vm.response.items.thumbnail.large)?_c('div',[_c('ModalImage',{directives:[{name:"show",rawName:"v-show",value:(_vm.showModal),expression:"showModal"}],attrs:{"imageData":_vm.response.items.thumbnail && _vm.response.items.thumbnail.large
          ? _vm.response.items.thumbnail.large
          : 'https://narasi.tv/storage/images/dummy.png'},on:{"close-modal":function($event){_vm.showModal = false}}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"container"},[(_vm.response.loading)?_c('ShimmerArticleLinimasa'):_vm._e(),_vm._v(" "),(!_vm.response.loading && _vm.response.items)?_c('div',{staticClass:"article-wrapper"},[(_vm.response.items.thumbnail && _vm.response.items.thumbnail.large)?_c('div',{staticClass:"img-wrapper"},[_c('ImageResponsive',{staticClass:"card-img-top",attrs:{"imageUrl":_vm.response.items.thumbnail && _vm.response.items.thumbnail.large
              ? _vm.response.items.thumbnail.large
              : 'https://narasi.tv/storage/images/dummy.png',"fromUrl":true,"alt":"image-linimasa"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"description"},[_c('div',{staticClass:"schedule d-flex"},[_c('div',{staticClass:"card-location fs-14"},[_vm._v(_vm._s(_vm.response.items.location))]),_vm._v(" "),_c('span',{staticClass:"px-2"},[_vm._v("•")]),_vm._v(" "),_c('div',{staticClass:"card-datetime fs-14"},[_vm._v("\n            "+_vm._s(_vm.$moment(_vm.response.items.startDate).format("DD/MM/YYYY"))+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"card-title d-block"},[_vm._v("\n          "+_vm._s(_vm.response.items.title)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"card-desc"},[_vm._v("\n          "+_vm._s(_vm.response.items.description)+"\n        ")]),_vm._v(" "),_c('button',{staticClass:"btn btn-lihat-foto fs-14 px-4",attrs:{"data-toggle":"modal","data-target":"#exampleModal"},on:{"click":function($event){_vm.showModal = true}}},[_vm._v("\n          LIHAT FOTO\n        ")])])]):_vm._e(),_vm._v(" "),_c('section',{staticClass:"recommend-event row"},[_c('div',{staticClass:"container"},[(_vm.$store.state.events.eventDataRecommend.items.length > 0)?_c('TitleSection',{attrs:{"title":"LINIMASA EVENT LAINNYA"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"position-relative"},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiper",value:(_vm.swiperOptions),expression:"swiperOptions",arg:"mySwiper"}],on:{"transition-start":function($event){return _vm.onSwiperSlideChange()}}},[_c('div',{staticClass:"swiper-wrapper"},[(_vm.$store.state.events.eventDataRecommend.loading)?_c('ShimmerListCard'):_vm._l((_vm.$store.state.events.eventDataRecommend
                  .items),function(card,index){return _c('CardEighteen',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.$store.state.events.eventDataRecommend.items.length > 0
                ),expression:"\n                  $store.state.events.eventDataRecommend.items.length > 0\n                "}],key:'card-linimasa-' + index,staticClass:"mb-3 swiper-slide",attrs:{"image":card.thumbnail && card.thumbnail.large
                    ? card.thumbnail.large
                    : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"organizer":card.organizer,"categories":card.categories,"location":card.location,"datetime":card.startDate,"title":card.title,"slug":card.slug,"description":card.description,"to":{
                  name: 'event-linimasa-event-narasi-slug',
                  params: { slug: card.slug },
                }}})})],2),_vm._v(" "),_c('div',{staticClass:"swiper-pagination"})]),_vm._v(" "),_c('div',{staticClass:"shadow-arrow shadow-left",class:_vm.arrow.prev ||
              _vm.$store.state.events.eventDataRecommend.items.length < 4
                ? 'hide'
                : ''}),_vm._v(" "),_c('div',{staticClass:"shadow-arrow shadow-right",class:_vm.arrow.next ||
              _vm.$store.state.events.eventDataRecommend.items.length < 4
                ? 'hide'
                : ''}),_vm._v(" "),_c('Icons',{staticClass:"arrow prev",class:_vm.arrow.prev ||
              _vm.$store.state.events.eventDataRecommend.items.length < 4
                ? 'hide'
                : '',attrs:{"name":"arrow-left"}}),_vm._v(" "),_c('Icons',{staticClass:"arrow next",class:_vm.arrow.next ||
              _vm.$store.state.events.eventDataRecommend.items.length < 4
                ? 'hide'
                : '',attrs:{"name":"arrow-right"}})],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"untukmu-page"},[_c('section',{attrs:{"id":"breadcrumbs"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"breadcrumbs"},[_c('Link',{attrs:{"to":'/'}},[_vm._v("HOME "),_c('Icons',{attrs:{"name":"arrow-right"}})],1),_vm._v(" "),_c('Link',{attrs:{"to":{
            name: 'indeks',
          }}},[_vm._v("INDEKS "),_c('Icons',{attrs:{"name":"arrow-right"}})],1),_vm._v(" "),_c('Link',{staticClass:"active",attrs:{"to":{
            name: _vm.$route.name,
            query: _vm.$route.query,
          }}},[_vm._v(_vm._s(_vm.title_interest ? _vm.title_interest : "Semua")+"\n          "),_c('Icons',{attrs:{"name":"arrow-right"}})],1)],1)])]),_vm._v(" "),_c('section',{staticClass:"class-category"},[_c('div',{staticClass:"container"},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiperClassCategory",value:(_vm.swiperOptionsClassCategory),expression:"swiperOptionsClassCategory",arg:"mySwiperClassCategory"}],staticClass:"py-2"},[_c('div',{staticClass:"swiper-wrapper"},[(_vm.interests.loading)?_c('ShimmerCategory'):_vm._e(),_vm._v(" "),_c('Link',{staticClass:"swiper-slide category",class:{ 'Link-exact-active': !_vm.$route.query.type },attrs:{"to":{
              name: 'indeks',
            }}},[_vm._v("Semua\n          ")]),_vm._v(" "),_vm._l((_vm.interests.items.slice(1)),function(item,index){return _c('Link',{key:item.id,staticClass:"swiper-slide category",class:item.slug == _vm.$route.query.type ? 'Link-exact-active' : '',attrs:{"to":{
              name: 'indeks',
              query: {
                type: item.slug,
              },
            }},nativeOn:{"click":function($event){return _vm.setTitleInterest(index)}}},[_vm._v(_vm._s(item.title)+"\n          ")])})],2),_vm._v(" "),_c('div',{staticClass:"swiper-pagination"})])])]),_vm._v(" "),_c('section',{attrs:{"id":"content-wrapper"}},[_c('div',{staticClass:"container"},[(_vm.title_interest)?_c('div',{staticClass:"title-wrapper"},[(_vm.interests.items && _vm.interests.items.length > 0)?_c('h2',{staticClass:"title"},[_vm._v("\n          "+_vm._s(_vm.title_interest)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.desc_interest)?_c('p',{staticClass:"desc"},[_vm._v("\n          "+_vm._s(_vm.desc_interest)+"\n        ")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"list-card-category"},[_vm._l((5),function(item,index){return _c('ShimmerCardHomeLandscape',{directives:[{name:"show",rawName:"v-show",value:(_vm.interest_article.loading),expression:"interest_article.loading"}],key:index})}),_vm._v(" "),_vm._l((_vm.interest_article.items),function(item){return _c('CardFive',{directives:[{name:"show",rawName:"v-show",value:(!_vm.interest_article.loading),expression:"!interest_article.loading"}],key:item.id,attrs:{"image":item.thumbnail && item.thumbnail.large
              ? item.thumbnail.large
              : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"duration":item.timeVideo ? item.timeVideo : '06:00',"channel":item.channel.title,"channelSlug":item.channel.slug,"programSlug":item.channel.program ? item.channel.program.slug : '',"category":item.category ? item.category.title : '',"categorySlug":item.category ? item.category.slug : '',"title":item.title,"isText":item.isText,"isAdvertorial":item.isAdvertorial,"isThumbnailSquare":true,"to":item.isText
              ? {
                  name: `read-channel-slug`,
                  params: {
                    channel: item.channel.slug,
                    slug: item.slug,
                  },
                  query: {
                    ref: 'indeks',
                  },
                }
              : {
                  name: 'video-channel-slug',
                  params: {
                    channel: item.channel.slug,
                    slug: item.slug,
                  },
                  query: {
                    ref: 'indeks',
                  },
                }}})})],2),_vm._v(" "),(
          _vm.interest_article.items.length > 0 &&
          _vm.interest_article.pagination.pages > 1
        )?_c('div',{staticClass:"pagination-cards"},[(
            _vm.interest_article.pagination &&
            _vm.interest_article.pagination.pages &&
            _vm.interest_article.pagination.pages > 1
          )?_c('paginate',{attrs:{"page-count":_vm.interest_article.pagination.pages,"page-range":3,"margin-pages":1,"prev-class":"arrow","next-class":"arrow","click-handler":_vm.clickCallback,"container-class":'pagination',"page-class":'page-item'},model:{value:(_vm.activePaginate),callback:function ($$v) {_vm.activePaginate=$$v},expression:"activePaginate"}},[_c('template',{slot:"prevContent"},[_c('Icons',{attrs:{"name":"arrow-left","color":"#757575"}})],1),_vm._v(" "),_c('template',{slot:"nextContent"},[_c('Icons',{attrs:{"name":"arrow-right","color":"#757575"}})],1)],2):_vm._e()],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
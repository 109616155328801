<template>
  <div class="content-main">
    <section id="breadcrumbs">
      <div class="container">
        <div class="breadcrumbs">
          <Link :to="'/'">HOME <Icons name="arrow-right" /></Link>
          <Link
            :to="{
              name: 'event',
            }"
            >EVENT <Icons name="arrow-right"
          /></Link>
          <Link
            :to="{
              name: $route.name,
              query: $route.query,
            }"
            class="active"
            >{{ title_interest }} <Icons name="arrow-right"
          /></Link>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="title-wrapper">
        <Shimmer
          width="50%"
          height="30px"
          class="rounded-pill mb-3"
          v-if="eventDataByCategory.loading"
        />
        <div class="title" v-else>Kategori Event Terpopuler</div>
        <Shimmer
          width="100%"
          height="30px"
          class="rounded-pill"
          v-if="eventDataByCategory.loading"
        />
        <div class="desc" v-else>
          Pilih minat Anda untuk mendapatkan saran acara berdasarkan apa yang
          Anda sukai
        </div>
      </div>
      <div
        class="category-wrapper"
        v-swiper:mySwiperCategories="swiperOptionsCategories"
        @transition-start="onSwiperSlideChange('categories')"
      >
        <div class="swiper-wrapper">
          <ShimmerCategory v-if="eventDataByCategory.loading" />
          <Link
            v-show="!eventDataByCategory.loading"
            :to="{
              name: 'event-category',
              query: {
                type: 'semua-topik',
              },
            }"
            class="swiper-slide category"
            :class="{
              'Link-exact-active': $route.query.type == 'semua-topik',
            }"
            >Semua Topik
          </Link>
          <Link
            v-show="!eventDataByCategory.loading"
            v-for="(item, index) in dataInterest"
            :key="index"
            :to="{
              name: 'event-category',
              query: {
                type: item.slug,
              },
            }"
            class="swiper-slide category"
            :class="{
              'Link-exact-active': $route.query.type == item.slug,
            }"
            >{{ item.title }}
          </Link>
        </div>
      </div>
    </div>
    <transition-group name="list" tag="div" class="container">
      <ShimmerCardSeeAll v-if="eventDataByCategory.loading" key="shimmer" />
      <CardThirteen
        v-for="(card, index) in eventDataByCategory.items"
        :key="'category-card-' + index"
        :image="
          card.thumbnail && card.thumbnail.large
            ? card.thumbnail.large
            : 'https://narasi.tv/storage/images/dummy.png'
        "
        :imageFromUrl="true"
        :organizer="card.organizerSlug"
        :categories="card.categories"
        :location="card.location"
        :title="card.title"
        :slug="card.slug"
        :description="card.description"
        :datetime="card.startDate"
        :price="card.price ? card.price : 0"
        :discount="card.discount ? card.discount : 100"
        :to="{
          name: 'event-organizer-slug',
          params: {
            organizer: card.organizerSlug,
            slug: card.slug,
          },
        }"
      />
    </transition-group>
    <div
      class="pagination-cards"
      v-if="
        eventDataByCategory.items.length > 0 &&
        eventDataByCategory.pagination &&
        eventDataByCategory.pagination.pages > 1
      "
    >
      <paginate
        v-if="
          eventDataByCategory &&
          eventDataByCategory.pagination.pages &&
          eventDataByCategory.pagination.pages > 1
        "
        :page-count="eventDataByCategory.pagination.pages"
        :page-range="3"
        :margin-pages="1"
        prev-class="arrow"
        next-class="arrow"
        :click-handler="clickCallback"
        :container-class="'pagination'"
        :page-class="'page-item'"
        v-model="activePaginate"
      >
        <template slot="prevContent">
          <Icons name="arrow-left" color="#757575" />
        </template>
        <template slot="nextContent">
          <Icons name="arrow-right" color="#757575" />
        </template>
      </paginate>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../../config";
import CardEleven from "/components/cards/CardEleven";
import CardThirteen from "/components/cards/CardThirteen";
import Vue from "vue";
import Paginate from "/node_modules/vuejs-paginate/src/components/Paginate";
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import Shimmer from "@/components/Shimmer";
import ShimmerCategory from "@/components/shimmer/Category";
import ShimmerCardSeeAll from "@/components/shimmer/CardSeeAll";

Vue.component("paginate", Paginate);

export default {
  watchQuery: true,
  async asyncData({ app, query, store, route }) {
    try {
      const response = await app.$axios.$get(
        `${config.EVENT_SERVICE_API}interests`
      );
      let dataInterest = response.data;
      var title_interest = "";
      for (const x in response.data) {
        if (response.data[x].slug === query.type) {
          title_interest = response.data[x].title;
        }
      }
      if (query.type === "semua-topik") {
        title_interest = "Semua Topik";
      }
      store.commit("seo/SET_SEO", {
        title: title_interest,
        desc: "Kumpulan event berdasarkan kategori " + title_interest,
        image: "https://narasi.tv/icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
      return { dataInterest, title_interest };
    } catch (err) {
      console.log(err);
    }
  },
  components: {
    ShimmerCardSeeAll,
    ShimmerCategory,
    Shimmer,
    Icons,
    Link,
    CardEleven,
    CardThirteen,
  },
  data() {
    return {
      activePaginate: this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1,
      page: 1,
      arrow: {
        prev: true,
        next: false,
      },
      swiperOptions: {
        // freeMode: true,
        pagination: false,
        autoHeight: true,
        speed: 1000,
        // autoplay: {
        //   delay: 5000,
        // },
        navigation: {
          prevEl: ".prev",
          nextEl: ".next",
        },
        mousewheel: {
          releaseOnEdges: true,
        },
        breakpoints: {
          1023: {
            slidesPerView: 3.5,
            spaceBetween: 30,
          },
          320: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
        },
      },
      swiperOptionsCategories: {
        // freeMode: true,
        pagination: false,
        autoHeight: true,
        calculateHeight: true,
        // autoplay: {
        //   delay: 5000,
        // },
        breakpoints: {
          1023: {
            slidesPerView: "auto",
            spaceBetween: 10,
            enabled: true,
            allowTouchMove: false,
            mousewheel: {
              releaseOnEdges: false,
            },
          },
          320: {
            slidesPerView: "auto",
            spaceBetween: 10,
            enabled: false,
            mousewheel: {
              releaseOnEdges: true,
            },
            // allowSlideNext: false,
            // allowSlidePrev: false,
          },
        },
      },
    };
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      eventDataByCategory: (state) => {
        return state.events.eventDataByCategory;
      },
    }),
  },
  watch: {
    "$route.query.type": {
      handler: function (interest) {
        if (!this.$route.query.page) {
          this.$store.dispatch("events/dataEventByCategory", {
            slug: interest == "semua-topik" ? "" : interest,
            page: this.page ? this.page : 1,
          });
        }
      },
      deep: true,
      immediate: true,
    },
    "$route.query.page": {
      handler: function (page) {
        if (page) {
          let page_ = parseInt(page);
          this.page = page_;
          this.$store.dispatch("events/dataEventByCategory", {
            slug:
              this.$route.query.type == "semua-topik"
                ? ""
                : this.$route.query.type,
            page: page,
          });
        } else {
          this.page = 1;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onSwiperSlideChange() {
      this.arrow.next = this.mySwiperCategories.isEnd;
      this.arrow.prev = this.mySwiperCategories.isBeginning;
    },
    clickCallback(page) {
      this.page = page;
      this.$router.replace({
        name: "event-category",
        query: this.$route.query.type
          ? {
              type: this.$route.query.type,
              page: page,
            }
          : { page: page },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-main {
  padding: 32px 0 48px;
  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s;
  }
  .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }

  #breadcrumbs {
    margin-bottom: 16px;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 16px;
    }
    .container {
      .breadcrumbs {
        display: flex;
        align-items: center;
        a {
          font-size: 14px;
          color: #616161;
          display: flex;
          align-items: center;
          svg {
            width: 18px;
            height: auto;
            margin: 0 10px;
          }
          &.active {
            color: #34128c;
            font-weight: 700;
          }
          &:hover {
            color: #616161 !important;
          }
          &:last-child {
            svg {
              display: none;
            }
          }
        }
      }
    }
  }
  .container {
    @media only screen and (max-width: 1024px) {
      margin-bottom: 24px;
    }
    .title-wrapper {
      margin-bottom: 24px;
      @media only screen and (max-width: 1024px) {
        margin-bottom: 16px;
      }

      .title {
        font-size: 40px;
        color: #4a25aa;
        font-weight: 700;
        margin-bottom: 15px;
        @media only screen and (max-width: 1024px) {
          font-size: 24px;
          margin-bottom: 8px;
        }
      }

      .desc {
        font-size: 20px;
        color: #757575;
        @media only screen and (max-width: 1024px) {
          font-size: 12px;
        }
      }
    }
    .category-wrapper {
      margin-bottom: 32px;
      &.swiper-container {
        @media only screen and (max-width: 1024px) {
          padding: 20px;
          margin: -20px;
        }

        .swiper-wrapper {
          @media only screen and (min-width: 1024px) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            row-gap: 10px;
          }
          height: fit-content !important;

          .swiper-slide {
            &.category {
              color: #616161;
              background: #fafafa;
              width: fit-content !important;
              border-radius: 50px;
              white-space: nowrap;
              font-size: 16px;
              padding: 10px 30px;
              border: 1px solid #e0e0e0;
              @media only screen and (max-width: 1024px) {
                font-size: 12px;
                padding: 5px 15px;
                margin: 0 5px 5px 0;
              }

              &:not(:last-child) {
                margin-right: 10px;
              }

              &:hover {
                @media only screen and (min-width: 1024px) {
                  background: #8166c8;
                  color: #fafafa !important;
                }
              }

              &:active {
                @media only screen and (min-width: 1024px) {
                  background: #6242b4;
                  color: #e0e0e0 !important;
                }
              }

              &.Link-exact-active {
                background: #4a25aa;
                color: #ffe900 !important;

                &:hover {
                  color: #ffe900 !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .pagination-cards {
    padding: 24px 0;

    .pagination {
      justify-content: center;
      align-items: center;
      margin: 0;
      @media only screen and (max-width: 1024px) {
        justify-content: center;
      }

      ::v-deep .arrow {
        display: none;
      }

      ::v-deep .page-item {
        a {
          font-size: 12px;
          font-weight: 400;
          color: #757575;
          background: #e0e0e0;
          width: 25px;
          height: 25px;
          margin: 0 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        &.active {
          a {
            background: #4a25aa;
            color: #9e9e9e !important;
          }
        }

        &:hover {
          a {
            background: #4a25aa;
            color: #9e9e9e !important;
          }
        }
      }
    }
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-main"},[_c('Breadcrumbs',{attrs:{"background":"#f1f1f1"}}),_vm._v(" "),_c('div',{staticClass:"container"},[_vm._m(0),_vm._v(" "),_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiperCategories",value:(_vm.swiperOptions.years),expression:"swiperOptions.years",arg:"mySwiperCategories"}],staticClass:"years d-flex justify-content-center"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.listYears),function(year,index){return _c('Link',{key:index,staticClass:"year swiper-slide",class:year === _vm.$route.query.tahun ? 'active' : '',attrs:{"to":{
          name: 'event-linimasa-event-narasi',
          query: {
            tahun: year,
          },
        }}},[_vm._v(_vm._s(year)+"\n        ")])}),1)]),_vm._v(" "),_c('div',{staticClass:"event-list"},[(_vm.linimasa.loading)?_c('ShimmerCardLinimasa'):_vm._e(),_vm._v(" "),_vm._l((_vm.linimasa.items),function(card,index){return _c('CardTwelve',{key:index,staticClass:"event-item",attrs:{"bgWhite":true,"image":card.thumbnail && card.thumbnail.large
        ? card.thumbnail.large
        : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"organizer":card.organizer,"categories":card.categories,"location":card.location,"datetime":card.startDate,"title":card.title,"slug":card.slug,"description":card.description,"createdAt":card.createdAt,"to":{
          name: 'event-linimasa-event-narasi-slug',
          params: {
            slug: card.slug,
          },
        }}})})],2)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-wrapper"},[_c('div',{staticClass:"title"},[_vm._v("LINIMASA EVENT NARASI")]),_vm._v(" "),_c('div',{staticClass:"desc"},[_vm._v("\n        Menampilkan konten-konten event Narasi yang telah berlalu berdasarkan\n        tahun pelaksanaan event.\n      ")])])
}]

export { render, staticRenderFns }